import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { Icon } from '../../../shared/Icon/Icon';
import type { RootState } from '../../../../store/reducers/rootReducer';
import { fetchGetJson } from '../../../../services/services';

interface Props {
  handleEditedInputs: Function,
  error: TODO,
}
interface Markets {
  name: string,
  languages: string[
  ] | TODO
}

export const SetupDetails = ({ handleEditedInputs, error }: Props) => {
  const { name, surveyType, country, language, liveUrl, testUrl,
    cpi, clientCPI, containsPersonalData, audienceStatus, hasBeenLive, targetType, gatekeeperTestUrl, gatekeeperLiveUrl, currency } = useSelector((state: RootState) => state.audienceDataReducer);
  const [didMount, setDidMount] = useState<boolean>(false)
  const [markets, setMarkets] = useState<Markets[]>([])
  const [serachMarket, setSearchMarket] = useState<string>("")
  const [isValidLiveUrl, setIsValidLiveUrl] = useState<boolean>(false)
  const [isValidTestUrl, setIsValidTestUrl] = useState<boolean>(false)
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const isStatusClosedOrReconciled = audienceStatus === "Closed" || audienceStatus === "Reconciled"
  const isStatusLiveOrHasBeenLive = audienceStatus === "Live" || hasBeenLive
  const curencySign = currency ? (currency === "GBP" ? "GBP (£)" : currency === "USD" ? "USD ($)" : "AUD (A$)") : "GBP (£)"

  useEffect(() => {
    if (!didMount) {
      fetchGetJson("au/a/markets", token).then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
        } else {
          setMarkets(res)
        }
      })
      setDidMount(true)
    }
  }, [token, didMount, dispatch])

  const returnMarketLanguage = (country: string | null): string[] => {
    const marketLanguages: string[] = []
    markets.map(item => {
      if (country === item.name) {
        marketLanguages.push(...item.languages)
      }
      return marketLanguages
    })
    return marketLanguages
  }

  const handleSearch = (marketSearch: string) => {
    setSearchMarket(marketSearch)
  }

  const replacingQueriesInTheUrl = (url: string) => {
    const id = Math.floor(Math.random() * 10000)
    const replaceId = url.replace(/\[%RID%]/, `${id.toString()}_test`)
    const redirectingUrl = replaceId.replace(/\[%PlatformID%]/, "0")
    window.open(redirectingUrl)
    gatekeeperLiveUrl && window.open(redirectingUrl)
    gatekeeperTestUrl && window.open(redirectingUrl)
  }

  const openURl = (urlType: string) => {
    if (urlType === "liveUrl") {
      gatekeeperLiveUrl && replacingQueriesInTheUrl(gatekeeperLiveUrl)
    } else {
      gatekeeperTestUrl && replacingQueriesInTheUrl(gatekeeperTestUrl)
    }
  }

  useEffect(() => {
    try {
      const validateLiveUrl: boolean = Boolean(new URL(liveUrl))
      setIsValidLiveUrl(validateLiveUrl)
    } catch (e) {
      setIsValidLiveUrl(false)
    }
  }, [liveUrl])

  useEffect(() => {
    try {
      const validateTestUrl: boolean = Boolean(new URL(testUrl))
      setIsValidTestUrl(validateTestUrl)
    } catch (e) {
      setIsValidTestUrl(false)
    }
  }, [testUrl])

  return (
    <article className='nav-board bg-white d-flex justify-content-center flex-column w-100 p-5'>
      <p className='innerboard-header d-flex align-items-center stronger mb-2 h-32' >Setup details</p>
      <div className='board-content-container'>
        <div className='row row-col-2 px-1'>
          <div className="details-input d-flex flex-column w-50 gap-sm">
            <label className='p-0 m-0 strong text-primary' htmlFor='AudienceName'>
              Audience name *
            </label>
            <input className='form-control' type="text" id='AudienceName' disabled={isStatusClosedOrReconciled} value={name} onChange={(e) => handleEditedInputs(e, "name")} />
            {error?.name &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
          </div>

          <div className="details-input dropdown details-dropdown d-flex flex-column w-50 gap-sm">
            <p className='p-0 m-0 strong details-input-text text-primary'>
              Survey type
            </p>
            <button disabled={isStatusLiveOrHasBeenLive || isStatusClosedOrReconciled} className={`btn dropdown-toggle form-control ${(isStatusLiveOrHasBeenLive || isStatusClosedOrReconciled) && ' disabled'}`} type="button" id="dropdownMenuAction" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {surveyType}
              <span>
                <Icon type="chevron-mini-down-alt" className="submenu" />
              </span>
            </button>

            <div className="dropdown-menu menu-action" aria-labelledby="dropdownMenuAction">
              {["AdHoc", "Tracker"].filter(item => item !== surveyType).map((item, index) => (
                <button key={index} className="dropdown-item" type="button" onClick={(e) => handleEditedInputs(e, "surveyType")} >{item}</button>
              ))}
            </div>

            {error?.surveyType &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
          </div>

          <div className="details-input dropdown details-dropdown d-flex flex-column w-50 gap-sm">
            <p className='p-0 m-0 strong text-primary details-input-text'>
              Market
            </p>
            <button disabled={isStatusLiveOrHasBeenLive || isStatusClosedOrReconciled} className={`btn dropdown-toggle form-control ${(isStatusLiveOrHasBeenLive || isStatusClosedOrReconciled) && ' disabled'}`} type="button" id="dropdownMenuAction" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {country?.name || "Choose Market"}
              <span>
                <Icon type="chevron-mini-down-alt" className="submenu" />
              </span>
            </button>

            <div className="dropdown-menu menu-action" aria-labelledby="dropdownMenuAction">
              <div className='d-flex px-3 pb-3 border-bottom'>
                <input className='form-control' type="text" placeholder="Search market" id="myInput" onChange={(e) => handleSearch(e.target.value)} />
              </div>

              <div className='d-flex flex-column market overflow-auto'>
                {markets.filter(market => market.name.toLocaleLowerCase().includes(serachMarket)).map((item, index) => (
                  <button key={index} className="dropdown-item" type="button" onClick={() => handleEditedInputs(item, "market")} >{item.name}</button>
                ))}
              </div>
            </div>

            {error?.marketName &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
          </div>

          <div className="details-input dropdown details-dropdown d-flex flex-column w-50 gap-sm">
            <p className='p-0 m-0 strong text-primary details-input-text'>
              Language *
            </p>
            <button disabled={isStatusLiveOrHasBeenLive || isStatusClosedOrReconciled} className={`btn dropdown-toggle form-control ${(isStatusLiveOrHasBeenLive || isStatusClosedOrReconciled) && ' disabled'}`} type="button" id="dropdownMenuAction" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {language?.name || "Choose Language"}
              <span>
                <Icon type="chevron-mini-down-alt" className="submenu" />
              </span>
            </button>

            <div className="dropdown-menu menu-action" aria-labelledby="dropdownMenuAction">
              {country?.name === "" && <button className="dropdown-item text-danger" type="button" >
                Please select market
              </button>}
              {country?.name && returnMarketLanguage(country.name).map((item: any, index) => (
                <button key={index} className="dropdown-item" type="button" onClick={() => handleEditedInputs(item, "language")} >{country.name === "" ? "Please select market" : item.name}</button>
              ))}
            </div>
            {error?.language &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
          </div>

          <div className="details-input input-group d-flex flex-column w-50 gap-sm">
            <div className='d-flex align-items-center'>
              <p className='p-0 m-0 strong text-primary details-input-text'>
                Live URL
              </p>

              <Tooltip anchorElement="target" position="top">
                <span className='icon cursor-pointer' title='[%RID%] to indicate where the Respondent ID should be populated. [%PID%] to indicate where the platform ID should be populated.'><Icon type="info-tooltip" className='pe-none' /></span>
              </Tooltip>
            </div>

            <div className="input-group">
              <input className='form-control' type="url" id='liveUrl' disabled={isStatusLiveOrHasBeenLive || isStatusClosedOrReconciled} placeholder='https://example.com' value={liveUrl === null ? "" : liveUrl} onChange={(e) => handleEditedInputs(e, "liveUrl")} />
              <div className="input-group-append">
                {isValidLiveUrl &&
                  <div className='input-group-text p-1 h-32' aria-disabled="true">
                    <Tooltip anchorElement="target" position="top">
                      <span title='Valid URL' className='d-flex w-100 h-100' >
                        <Icon className='pe-none' fill="green" type="checkmark" />
                      </span>
                    </Tooltip>
                  </div>
                }

                <div onClick={() => openURl("liveUrl")} aria-disabled="true" className="input-group-text py-1 px-2 h-32">
                  <Tooltip anchorElement="target" position="top">
                    <span className="icon cursor-pointer" title={`${gatekeeperLiveUrl ? "Live URL" : "Test URL is not provided"}`}>
                      <Icon type="help-external" fill={gatekeeperLiveUrl ? "" : "gray"} className='pe-none' />
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
            {error?.liveUrl &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
            {error?.invalidLiveUrl &&
              <div className="text-danger">
                <small>Inavalid URL format.</small>
              </div>}
          </div>

          <div className="details-input d-flex flex-column w-50 gap-sm">
            <label className='p-0 m-0 strong text-primary' htmlFor='testURL'>Test URL</label>
            <div className="input-group">
              <input id="testURL" className='form-control' type="url" disabled={isStatusLiveOrHasBeenLive || isStatusClosedOrReconciled} placeholder='https://example.com' value={testUrl === null ? "" : testUrl} onChange={(e) => handleEditedInputs(e, "testUrl")} />
              <div className="input-group-append">
                {isValidTestUrl &&
                  <div className='input-group-text p-1 h-32' aria-disabled="true">
                    <Tooltip anchorElement="target" position="top">
                      <span title='Valid URL' className='d-flex w-100 h-100' >
                        <Icon className='pe-none' fill="green" type="checkmark" />
                      </span>
                    </Tooltip>
                  </div>
                }
                <div onClick={() => openURl("testUrl")} aria-disabled="true" className="input-group-text py-1 px-2 h-32">
                  <Tooltip anchorElement="target" position="top">
                    <span className="icon cursor-pointer" title={`${gatekeeperTestUrl ? "Test URL" : "Test URL is not provided"}`}>
                      <Icon type="help-external" fill={gatekeeperTestUrl ? "" : "gray"} className='pe-none' /></span>
                  </Tooltip>
                </div>
              </div>
            </div>
            {error?.testUrl &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
            {(error?.invalidTestUrl && !error?.testUrl) &&
              <div className="text-danger">
                <small>Inavalid URL format.</small>
              </div>}
          </div>

          <div className="details-input dropdown details-dropdown d-flex flex-column w-50 gap-sm">
            <p className='p-0 mb-1 strong text-primary details-input-text'>
              Target type
            </p>

            <button disabled={isStatusLiveOrHasBeenLive || isStatusClosedOrReconciled} className={`btn dropdown-toggle form-control ${(isStatusLiveOrHasBeenLive || isStatusClosedOrReconciled) && ' disabled'}`} type="button" id="dropdownMenuAction" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {targetType}
              <span>
                <Icon type="chevron-mini-down-alt" className="submenu" />
              </span>
            </button>

            <div className="dropdown-menu menu-action" aria-labelledby="dropdownMenuAction">
              {["Completes", "Prescreens"].filter((item: string) => item !== targetType).map((item: any, index) => (
                <button key={index} className="dropdown-item" type="button" onClick={() => handleEditedInputs(item, "targetType")} >{item}</button>
              ))}
            </div>
            {error?.language &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
          </div>

          <div className='d-flex w-50'>
            <div className="details-input input-group d-flex flex-column w-50 gap-sm">
              <div className='d-flex flex-row justify-content-between'>
                <div className='d-flex align-items-center'>
                  <p className="p-0 m-0 strong text-primary details-input-text">
                    Sample CPI *
                  </p>

                  <Tooltip anchorElement="target" position="top">
                    <span className='icon cursor-pointer' title='Visible to Walr employees'>
                      <Icon type="info-tooltip" className='pe-none' />
                    </span>
                  </Tooltip>
                </div>
              </div>

              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text m-0 p-0 h-32">
                    <div className="dropdown">
                      <button className="btn shadow-none dropdown-toggle h-32 pr-05" type="button" id="dropdownMenuStatus" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {curencySign}
                        <span><Icon type="chevron-mini-down-alt" className="submenu" />
                        </span>
                      </button>
                      <div className="dropdown-menu border-0" aria-labelledby="dropdownMenuStatus">
                        {["GBP (£)", "USD ($)", "AUD (A$)"].map((items, index) => (
                          <button key={index} className="dropdown-item" type="button" onClick={() => handleEditedInputs(items, "currency")
                          }>{items}</button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <input type="number" title='cpi' min={0.1} step="0.01" id="sampleCpi" required disabled={isStatusClosedOrReconciled} className="form-control" value={cpi || cpi === 0 ? cpi : ""} onChange={(e) => handleEditedInputs(e, "cpi")} />
              </div>
              {error?.cpi &&
                <div className="text-danger">
                  <small>This field is required.</small>
                </div>}
            </div>

            <div className="details-input input-group d-flex flex-column w-50 gap-sm">
              <div className='d-flex flex-row justify-content-between'>
                <div className='d-flex align-items-center'>
                  <p className='p-0 m-0 strong text-primary details-input-text'>
                    Client CPI
                  </p>

                  <Tooltip anchorElement="target" position="top">
                    <span className='icon cursor-pointer' title='Visible to Walr employees'>
                      <Icon type="info-tooltip" className='pe-none' />
                    </span>
                  </Tooltip>
                </div>
              </div>

              <div className="input-group">
                <div className="input-group-prepend">
                  <span aria-disabled="true" className="input-group-text medium text-primary strong py-1">{curencySign}</span>
                </div>
                <input type="number" title='cpi' min={0.1} step="0.01" required disabled={isStatusClosedOrReconciled} className="form-control" id="clientCpi" value={clientCPI || clientCPI === 0 ? clientCPI : ""} onChange={(e) => handleEditedInputs(e, "clientCpi")} />
              </div>
              {error?.clientCpi &&
                <div className="text-danger">
                  <small>This field is required.</small>
                </div>}
            </div>
          </div>
          {/* radio buttons */}
          <div className="details-input d-flex flex-column w-50 px-3 pt-3 pb-1 gap-md">
            <p className='p-0 mb-0 text-primary strong details-input-text'>
              Does your survey collect personal identifiable information (PII)?
            </p>

            <div className='d-flex flex-row align-items-center gap-md'>
              <div className="d-flex align-items-center gap-md cursor-pointer">
                <input className='cursor-pointer h-32 w-20' id="yes" type="radio" name="inlineRadioOptions2" style={{ accentColor: "#172147" }} disabled={isStatusLiveOrHasBeenLive || isStatusClosedOrReconciled} checked={!!containsPersonalData} value="Yes" onChange={(e) => handleEditedInputs(e, "pii")} />
                <label className="d-flex cursor-pointer m-0 align-items-center align-items-center" htmlFor="yes">
                  Yes
                </label>
              </div>

              <div className="d-flex align-items-center gap-md cursor-pointer">
                <input className='cursor-pointer h-32 w-20 text-primary' id="no" type="radio" style={{ accentColor: "#172147" }} disabled={isStatusLiveOrHasBeenLive || isStatusClosedOrReconciled} checked={!containsPersonalData} name="inlineRadioOptions2" value="No" onChange={(e) => handleEditedInputs(e, "pii")} />
                <label className="d-flex cursor-pointer m-0 align-items-center align-items-center" htmlFor="no">
                  No
                </label>
              </div>
            </div>
          </div>

          {/* Comment out for now, value is set to true
          <div className="details-input d-flex flex-column w-50 px-3 pt-3 pb-1 gap-md">
            <p className='p-0 mb-0 text-primary strong details-input-text'>
              Dedupe between multiple panels
            </p>

            <div className='d-flex flex-row align-items-center gap-md'>
              <div className="d-flex align-items-center gap-md cursor-pointer">
                <input className='cursor-pointer h-32 w-20' id="Multiple" type="radio" name="yes" style={{ accentColor: "#172147" }} checked={panelType === 'Multiple'} value="Multiple" onChange={(e) => handleEditedInputs(e, "panelSource")} />
                <label className="d-flex cursor-pointer m-0 align-items-center" htmlFor="Multiple">Yes</label>
              </div>

              <div className="d-flex align-items-center gap-md cursor-pointer">
                <input className='cursor-pointer h-32 w-20' id="Single" type="radio" style={{ accentColor: "#172147" }} checked={panelType === 'Single'} name="no" value="Single" onChange={(e) => handleEditedInputs(e, "panelSource")} />
                <label className="d-flex cursor-pointer m-0 align-items-center" htmlFor="Single">No</label>
              </div>
            </div>
          </div> */}
        </div>
        {/* radio buttons */}
      </div>
    </article>
  )
}