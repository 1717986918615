import { Fragment, useMemo, useState } from 'react';
import JsxParser from 'react-jsx-parser';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { ExportModal } from '../../ExportModal/ExportModal';
import { ZoomButtons } from '../../../../shared/ZoomButtons/ZoomButtons';
import Pagination from '../../../../../../../shared/Pagination/Pagination';
import type { RootState } from '../../../../../../../../store/reducers/rootReducer';
import ChartTypeContent from '../ChartTabContent/ChartTypeContent/ChartTypeContent';
import { DropdownButton } from '../../../../../../../shared/DropdownButton/DropdownButton';
import returnActionsData from '../../../../shared/helpers/returnActionsData/returnActionsData';
import { FullHeightSpinner } from '../../../../../../../shared/FullHeightSpinner/FullHeightSpinner';

type AnalysisScriptingItemResult = {
  type: string
  result: string
  analysisType: string
}

type ShowExportModal = {
  show: boolean
  type: string | null
  action: string | null
  extension: string | null
}

type Props = {
  optionsData: {
    exportOptions: { exportType: string }[]
    dropdownValues: unknown
  }
  datasetType: string
  datasetName: string
  loadingAnalysisScriptingResult: boolean
  selectedAnalysisScriptingItem: string | null
}

const exportDropdownItems = [{ text: 'Export as Word', id: 'word', actionName: 'Word' }, { text: 'Export as Excel', id: 'excel', actionName: 'Excel' }, { text: 'Export as Powerpoint', id: 'ppt', actionName: 'PowerPoint' }]

export const AnalysisScriptingTabContent = ({ optionsData, datasetType, datasetName, loadingAnalysisScriptingResult, selectedAnalysisScriptingItem }: Props) => {
  const PageSize = 1;
  const history = useHistory();
  const params: { name: string, dataset: string } = useParams();
  const [currentPage, setCurrentPage] = useState(1)
  const [showExportModal, setShowExportModal] = useState<ShowExportModal>({ show: false, action: null, type: null, extension: null })
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { result, data } = useSelector((theState: RootState) => theState.analysisScriptingStateReducer)
  const userSettings = useSelector((theState: RootState) => theState.userSettingsReducer);
  const zoomLevel = userSettings.zoomLevels.analysisScriptingZoom;

  const currentTableData: AnalysisScriptingItemResult[] = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return result.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, result]);

  const onExportItemClick = (action: string, actionName: string) => {
    setShowExportModal({ show: true, action, type: actionName, extension: action })
  }

  return (
    <Fragment>
      {
        loadingAnalysisScriptingResult ?
          <div className={"d-flex flex-column overflow-hidden flex-fill border-left"}>
            <FullHeightSpinner />
          </div>
          :
          <div className={"d-flex flex-column overflow-hidden flex-fill border-left"}>
            {
              showExportModal?.show &&
              // @ts-ignore
              <ExportModal
                state={{ questions: [], firstColumn: [], scndColumn: [] }}
                token={token}
                history={history}
                tab={'analysisScripting'}
                datasetName={datasetName}
                datasetType={datasetType}
                datasetId={params.dataset}
                showExportModal={showExportModal}
                onExportHandler={returnActionsData}
                options={{ exportOptions: optionsData.exportOptions, values: optionsData.dropdownValues }}
                editorValue={selectedAnalysisScriptingItem ? data.find((el: { id: string }) => el.id === selectedAnalysisScriptingItem).xml : ''}
                onHide={() => setShowExportModal({ ...showExportModal, show: false })}
              />
            }
            {
              currentTableData.length === 1 &&
              <div className="d-flex justify-content-end align-items-center border-bottom analysis-actions p-2">
                <ZoomButtons
                  userSettings={userSettings}
                  dropdownMenuPosition='left'
                  zoomType={'analysisScriptingZoom'}
                />
                <DropdownButton
                  text='Export'
                  textField='text'
                  className='btn btn-transparent'
                  onItemClick={(e: { item: { id: string, actionName: string } }) => onExportItemClick(e.item.id, e.item.actionName)}
                  items={exportDropdownItems}
                />
              </div>
            }
            {
              currentTableData.map((item, index: number) => {
                if (item.type === 'html') {
                  return (
                    <div
                      key={index}
                      className={`d-flex flex-column overflow-auto ${item.analysisType}`}
                      style={{ flex: '1 1 auto', fontSize: `${((zoomLevel) * 0.875).toString()}rem` }}>
                      <JsxParser jsx={item.result} />
                    </div>
                  )
                }
                if (item.type === 'json') {
                  return (
                    <div style={{ flex: '1 1 auto' }} className={"d-flex flex-column overflow-auto p-4"} key={index}>
                      <ChartTypeContent
                        data={[JSON.parse(item.result)]}
                        currentZoom={zoomLevel}
                        additionalSettings={undefined}
                      />
                    </div>
                  )
                }
              })
            }
            {
              result.length > 1 &&
              <div className="d-flex border-top p-3">
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={result.length}
                  pageSize={PageSize}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
              </div>
            }
            {
              currentTableData.length === 0 &&
              <h5 className='h-100 d-flex justify-content-center align-items-center text-muted m-0'>
                Select an item from the list to view results
              </h5>
            }
          </div>
      }
    </Fragment>
  )
}