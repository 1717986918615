import { Switch } from '@progress/kendo-react-inputs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers/rootReducer';
import { DateTime } from 'luxon';

interface Props {
  handleEditedInputs: (e: TODO, inputFiledType: string) => void,
  error: TODO
}

// export const getCurrentDate = () => {
//   const date = new Date()
//   let day = date.getDate();
//   let month = date.getMonth() + 1;
//   let year = date.getFullYear();

//   const d = day > 9 ? day : "0" + day
//   const m = month > 9 ? month : "0" + month

//   return `${year}-${m}-${d}`;
// }

export const getCurrentDate = () => DateTime.now().toFormat('yyyy-LL-dd');

export const Timeline = ({ handleEditedInputs, error }: Props) => {
  const { completeAsap, targetEndDate, launchDate, audienceStatus, hasBeenLive } = useSelector((state: RootState) => state.audienceDataReducer);
  const isStatusClosedOrReconciled = audienceStatus === "Closed" || audienceStatus === "Reconciled";

  return (
    <article className='nav-board bg-white d-flex justify-content-center flex-column w-100 mb-0 p-5 gap-md'>
      <p className='innerboard-header d-flex align-items-center stronger m-0 h-32'>
        Timeline
      </p>

      <div className='board-content-container'>
        <div className='row row-col-2 px-1'>
          <div className="details-input d-flex flex-column w-50 gap-md">
            <label className='p-0 m-0 strong text-primary' htmlFor='datepickStart'>
              Start date
            </label>

            <input
              className='datepicker form-control d-block'
              type="date"
              id='datepickStart'
              min={getCurrentDate()}
              disabled={hasBeenLive || isStatusClosedOrReconciled}
              value={launchDate ? launchDate?.slice(0, 10) : getCurrentDate()}
              onChange={(e) => handleEditedInputs(e, "startDate")} />
            {error?.launchDate &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>
            }
          </div>
          <div className="details-input d-flex flex-column w-50 gap-md">
            <label className='p-0 m-0 strong text-primary' htmlFor='datepickEnd'>
              End date
            </label>

            <input
              className='form-control d-block'
              type="date"
              min={launchDate?.slice(0, 10)}
              disabled={completeAsap || isStatusClosedOrReconciled}
              id='datepickEnd'
              value={completeAsap ? "" : targetEndDate ? targetEndDate?.slice(0, 10) : undefined || ""} onChange={(e) => handleEditedInputs(e, "endDate")} />
            {(error?.targetEndDate && !completeAsap) &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>
            }
            <div className="customer-dialog d-flex align-items-center flex-row p-0 gap-md">
              <Switch
                className="asap-switch"
                id='adHocCompleteAsap'
                disabled={isStatusClosedOrReconciled}
                checked={completeAsap}
                onChange={(e: TODO) => handleEditedInputs(e, "asap")}
                size="small"
              />

              <p className="form-check-label d-flex align-items-center">
                Complete as soon as possible
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>
  )
}