import type React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ReconcileModal } from '../shared/ReconcileModal';
import { ReconcileTable } from './ReconcileTable/ReconcileTable';
import type { RootState } from '../../../store/reducers/rootReducer';
import { SupplierAccordion } from './SupplierTable/SupplierAccordion';
import { ReconcilePreview } from './ReconcilePreview/ReconcilePreview';
import type { ModalData, ReconciliationData } from '../../../interfaces/reconciliationsInterfaces/gateKeeperInterfaces';
import { fetchDeleteWithBody, fetchGetJson, fetchPostResOrJson } from '../../../services/services';

export const AudienceReconcile = () => {
  const [showNewReconcileModal, setShowNewReconcileModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [reconciliationData, setReconciliationData] = useState<ReconciliationData>()
  const [didMount, setDidMount] = useState<boolean>(false);
  const [error, setError] = useState({})
  const [modalData, setModalData] = useState<ModalData>({
    projectId: "",
    respondentIds: [],
    type: ""
  })
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const params: { name: string } = useParams()
  const dispatch = useDispatch()

  const handleErrorNewObject = (obj: ModalData) => {
    let emptyFieldError = {}
    if (obj.type === "") {
      emptyFieldError = Object.assign({ ...emptyFieldError, type: true })
    }
    if (obj.respondentIds.length < 1) {
      emptyFieldError = Object.assign({ ...emptyFieldError, respondentIds: true })
    }
    if (Object.keys(emptyFieldError).length > 0) {
      setError({ ...emptyFieldError })
      return false
    }
    return true
  }

  const handleNewReconcile = () => {
    setError({})
    setModalData({
      projectId: "",
      respondentIds: [],
      type: ""
    })
    setShowNewReconcileModal(true)
  }
  const submitReconcile = () => {
    fetchPostResOrJson(`au/r/${reconciliationData?.id}/reconcile?projectId=${params.name}`, token, null)
      .then(async (res: TODO) => {
        const body = await res.json()
        setReconciliationData(body)
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Reconciliation has been submitted' } })
      })
  }

  const discardReconcile = () => {
    fetchDeleteWithBody(`au/r/${reconciliationData?.id}/discard?projectId=${params.name}`, token, null)
      .then(async (res: TODO) => {
        try {
          const body = await res.json()
          setReconciliationData(body)
        } catch (err) {
          setReconciliationData(undefined)
          setDidMount(false)
        }
      })
  }

  const onHide = () => {
    setShowNewReconcileModal(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const arr: TODO = []
    arr.push(e.target.value.split('\n'))
    setModalData({ ...modalData, projectId: params.name, [e.target.name]: e.target.name === "respondentIds" ? e.target.value.split(/\n/) : e.target.value })
  }

  const onPreview = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (handleErrorNewObject(modalData)) {
      setIsLoading(true)
      fetchPostResOrJson("au/r/preview", token, modalData)
        .then(async (res: TODO) => {
          try {
            setIsLoading(false)
            setDidMount(false)
            const body = await res.json()
            setReconciliationData(body)

          } catch (err) {
            setReconciliationData(res)
          }
        })
      setShowNewReconcileModal(false);
    }
  };

  const handleExport = () => {
    setIsLoading(true)
    fetchGetJson(`au/r/${reconciliationData?.id}/export`, token)
      .then(() => {
        setDidMount(false)
        if (reconciliationData) {
          if (reconciliationData.status === "Reconciled") {
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Reconciled IDs successfully exported' } })
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "No reconciled IDs found" } });
          }
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "An error occured while exporting" } })
        }
        setIsLoading(false)
      }).catch((res: TODO) => {
        setIsLoading(false)
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `Export file: ${res.detail}` || res.message } });
      })
  }

  useEffect(() => {
    if (!didMount) {
      setDidMount(true)
      setIsLoading(true)
      fetchGetJson(`au/r/projects/${params.name}`, token)
        .then(async (res: TODO) => {
          if (res) {
            setIsLoading(false)
            setReconciliationData(res)
          } else {
            setIsLoading(false)
          }
        }).catch(async (res: TODO) => {
          setIsLoading(false)
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.status !== 200 ? `Error get Reconciliations: ${res.detail}` : res.message } });
        })
    }
  }, [didMount, token, params.name, dispatch])

  return (
    <div className='board-container reconcile-container d-flex justify-content-center flex-column w-100' >

      <article className='nav-board d-flex flex-column bg-white answer-layout p-5 gap-lg'>
        <ReconcileTable
          reconciliationData={reconciliationData}
          isLoading={isLoading}
          handleNewReconcile={handleNewReconcile}
          handleExport={handleExport}
        />
        {reconciliationData?.status === "Preview" &&
          <ReconcilePreview
            reconciliationData={reconciliationData}
            discardReconcile={discardReconcile}
            submitReconcile={submitReconcile}
          />}
        {reconciliationData?.summary &&
          <SupplierAccordion
            reconciliationSummary={reconciliationData?.summary}
            isLoading={isLoading}
          />}
      </article >
      {showNewReconcileModal &&
        <ReconcileModal
          onHide={onHide}
          modalData={modalData}
          handleChange={handleChange}
          handlePreviewSubmit={onPreview}
          error={error}
        />}
    </div >
  )
}