import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ChartReport } from './Chart/ChartReport';
import type { RootState } from '../../../store/reducers/rootReducer';
import { FieldworkMetrics } from './FieldworkMetrics/FieldworkMetrics';
import { fieldworkDashboardData } from '../../Survey/SurveyTabContent/SurveyDesignTabContent/helpers/returnDataHelpers/returnDataHelpers';
import { fetchGetJson as getData, fetchGetJson as getQuotas } from '../../../services/services';

export const AudienceReports = () => {
  const dispatch = useDispatch();
  const params: { name: string, survey: string } = useParams();
  const [didMount, setDidMount] = useState<boolean>(false);
  const [dashboardData, setDashboardData] = useState<TODO>(null);
  const [completesOverTime, setCompletesOverTime] = useState<TODO>([]);
  const [fieldworkMetricsData, setFieldWorkMetricsData] = useState([
    { value: '', code: "incidenceRate", text: 'Incidence rate', icon: 'incidence-rate' },
    { value: '', code: "conversionRate", text: 'Conversion rate', icon: 'conversion-rate' },
    { value: '', code: "avgInterviewLengthSeconds", text: 'Length of interview', icon: 'interview-length' },
    { value: '', code: "dropOutRate", text: 'Drop out rate', icon: 'drop-out' }
  ])
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  // TODO: HANDLE WHEN DATA PROVIDED
  useEffect(() => {
    if (!didMount) {
      setDidMount(true)
      if (params.name && params.survey) {
        getQuotas(`projects/${params.name}/surveys/${params.survey}/quota-overview`, token)
          .then((res: TODO) => {
            if (res && !res.error && !res.message) {
              // Set quotas and get overview data
              getData(`su/projects/${params.name}/surveys/${params.survey}/overview`, token)
                .then((res: TODO) => {
                  if (res && (res.error || res.message)) {
                    dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
                  } else {
                    setDashboardData(res)
                    setFieldWorkMetricsData(fieldworkMetricsData.map((el: TODO) => {
                      return {
                        ...el,
                        value: Number.isInteger(res[el.code]) ? res[el.code] : res[el.code].toString().substring(2, 4)
                      }
                    }))
                    if (Object.keys(res.completesOverTime).length > 0) {
                      if (Object.values(res.completesOverTime).length === 1) {
                        setCompletesOverTime([
                          {
                            date: new Date(Object.keys(res.completesOverTime)[0]).toLocaleDateString('default', { month: 'long', day: 'numeric' }),
                            value: Object.values(res.completesOverTime)[0]
                          },
                          {
                            date: "",
                            value: Object.values(res.completesOverTime)[0]
                          },
                        ])
                      } else {
                        setCompletesOverTime(fieldworkDashboardData(new Date(Object.keys(res.completesOverTime)[0]), new Date(Object.keys(res.completesOverTime)[Object.keys(res.completesOverTime).length - 1]), res.completesOverTime))
                      }
                    }
                  }
                })
            } else {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
            }
          })
      }
    }
  }, [params, token, didMount, fieldworkMetricsData, dispatch])
  return (
    <div className='board-container d-flex justify-content-center flex-column w-100 gap-lg'>
      <ChartReport completesOverTime={completesOverTime} dashboardData={dashboardData} />
      <FieldworkMetrics dashboardData={dashboardData} fieldworkMetricsData={fieldworkMetricsData} />
    </div >
  )
}