import { useEffect, useState } from 'react'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'

interface Props {
  onHide: () => void,
  message: any
}

export const ErrorModal = ({ onHide, message }: Props) => {
  const [modalMessage, setModalMessage] = useState<string>("")

  useEffect(() => {
    if (Object.keys(message).length === 0) {
      setModalMessage("No question selected! Please select any question so you can continue with creating Custom Audience")
    } else {
      let startMessage = message.start && message.start.message
      let endMessage = message.end && message.end.message
      if (message.allAnswersDeleted) {
        setModalMessage(message.allAnswersDeleted)

      } else if (message.start && message.end) {
        setModalMessage(`Start age is ${startMessage} and end age is ${endMessage}.`)
      } else if (message.start) {
        setModalMessage(`Start age is ${startMessage}.`)

      } else if (message.end) {
        setModalMessage(`End age is ${endMessage}.`)
      }
    }
  }, [message])

  return (
    <Dialog width={"25%"} className='reconcile-modal' contentStyle={{ padding: 0, fontSize: 14 }} title={"Custom modal error"} onClose={onHide}>
      <div className='p-4' >
        <p className='p-0 m-0 text-start' ><label className='p-0 m-0 medium' htmlFor="reconcileModal">Error:</label></p>
        <p className='p-0 m-0 text-start text-muted' ><label className='medium' htmlFor="reconcileModal">{modalMessage}</label></p>
      </div>
      <DialogActionsBar>
        <div className='p-2'>
          <button
            type='submit'
            className="k-button rounded bg-primary text-white medium"
            onClick={onHide}
          >
            Close
          </button>
        </div>
      </DialogActionsBar>
    </Dialog >
  )
}