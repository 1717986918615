import type { ReconciliationData } from '../../../../interfaces/reconciliationsInterfaces/gateKeeperInterfaces'

interface Props {
  reconciliationData: ReconciliationData,
  submitReconcile: () => void,
  discardReconcile: () => void
}

export const ReconcilePreview = ({ reconciliationData, submitReconcile, discardReconcile }: Props) => {
  return (
    <div className='d-flex justify-content-center'>
      <div className='gk-action-panel' style={{ width: '44rem' }}>
        <div className='regular strong text-white'>{reconciliationData?.respondentCount} WalrID(s) ready for reconciliation</div>
        <div className='d-flex'>
          <button className="btn btn-transparent primary mr-2" type="button" id="submitReconcile" onClick={() => discardReconcile()}>
            Discard
          </button>
          <button className="btn btn-success" type="button" id="submitReconcile" onClick={() => submitReconcile()}>
            Submit reconciliation
          </button>
        </div>
      </div>
    </div>
  )
}