import type { AudienceTabsType, AudiencesData, StatusCounts } from "../../interfaces/auddineceAccessInterfaces/audienceTypes";

type Props = {
  selectedTab: AudienceTabsType
  setSelectedTab: (tab: AudienceTabsType) => void;
  setIsOverlayLoading: (overlayLoading: boolean) => void;
  isLoading: boolean
  audiencesData: AudiencesData
  searchTerm: string | null;
  selectedMarket: { name: string, isoCode: string }
  fetchAudiencesData: (searchTermValue: string, selectedMarketValue: string, status: string, continuationToken?: string) => void;
}

export const AudiencesNav = ({ selectedTab, setSelectedTab, setIsOverlayLoading, isLoading, audiencesData, searchTerm, selectedMarket, fetchAudiencesData }: Props) => {
  return (
    <nav className="nav-sticky border-bottom px-3 bg-white">
      <ul className='nav nav-pills content-tabs flex-grow-1'>
        <li
          className={`nav-link ${selectedTab === 'All' && 'active'}`}
          onClick={() => {
            setSelectedTab('All');
            setIsOverlayLoading(true);
            selectedTab !== 'All' && !isLoading && fetchAudiencesData(searchTerm ? searchTerm : '', selectedMarket.isoCode, '')
          }}
        >
          All
        </li>

        {audiencesData?.statusCounts?.map((data: StatusCounts, index: number) => (
          <li
            key={index}
            className={`nav-link ${selectedTab === data.name && 'active'}`}
            onClick={() => {
              setSelectedTab(data.name);
              setIsOverlayLoading(true);
              selectedTab !== data.name && !isLoading && fetchAudiencesData(searchTerm ? searchTerm : '', selectedMarket.isoCode, data.name)
            }}
          >
            {data.name} ({data.number})
          </li>
        ))}
      </ul>
    </nav>
  )
}