import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers/rootReducer';
interface Props {
  handleEditedInputs: Function,
  error: any
}

export const Metrics = ({ handleEditedInputs, error }: Props) => {
  const { lengthOfInterview, incidenceRate, quantity, audienceStatus } = useSelector((state: RootState) => state.audienceDataReducer);
  const isStatusClosedOrReconciled = audienceStatus === "Closed" || audienceStatus === "Reconciled"

  return (
    <article className='nav-board bg-white d-flex justify-content-center flex-column w-100 p-5'>
      <p className='innerboard-header d-flex align-items-center stronger mb-2 h-32'>
        Metrics
      </p>

      <div className='board-content-container' >
        <div className='row row-col-2 px-1'>
          <div className="details-input d-flex flex-column w-50 gap-md">
            <label className='p-0 m-0 strong text-primary' htmlFor='targetCompletes'>
              Target completes *
            </label>

            <input id="targetCompletes" className='form-control' type="text" disabled={isStatusClosedOrReconciled} value={quantity || ""} onChange={(e) => handleEditedInputs(e, "quantity")} />
            {error?.quantity &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
          </div>

          <div className="details-input input-group d-flex flex-column w-50 gap-md">
            <label className='p-0 m-0 strong text-primary' htmlFor='targetCompletionLOI'>
              Target completion LOI *
            </label>

            <div className="input-group">
              <input className='form-control' type="text" id='targetCompletionLOI' disabled={isStatusClosedOrReconciled} value={lengthOfInterview ? lengthOfInterview : ""} onChange={(e) => handleEditedInputs(e, "loi")} />

              <div className="input-group-append">
                <span aria-disabled="true" className="input-group-text py-1 time-span">
                  minutes
                </span>
              </div>
            </div>

            {error?.lengthOfInterview &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
          </div>
        </div>

        <div className='row row-col-2 px-1'>
          <div className="details-input input-group d-flex flex-column w-50 gap-md">
            <label className='p-0 m-0 strong text-primary' htmlFor='targetIncidenceRate'>
              Target incidence rate *
            </label>

            <div className="input-group">
              <input className='form-control' type="text" id='targetIncidenceRate' disabled={isStatusClosedOrReconciled} value={incidenceRate ? incidenceRate : ""} onChange={(e) => handleEditedInputs(e, "rate")} />

              <div className="input-group-append">
                <span aria-disabled="true" className="input-group-text h-32">%</span>
              </div>
            </div>

            {error?.incidenceRate &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
          </div>
        </div>
      </div>
    </article >
  )
}