import type { Screening } from '../../../../interfaces/auddineceAccessInterfaces/auddineceAccessInterfaces'

interface Props {
  data: Screening[]
}

export const TagretingScreening = ({ data }: Props) => {
  return (
    <article className='nav-board d-flex flex-column bg-white answer-layout p-5 mb-4'>
      <p className="innerboard-header d-flex align-items-center stronger mb-4 h-32">Screening</p>
      {data.length < 1 ?
        <div className='board-content-container' >
          <table className="table target-table border mb-0">
            <tbody>
              <p className='d-flex justify-content-center p-4 w-100 m-0 text-muted'>No data provided</p>
            </tbody>
          </table>
        </div>

        :
        <div className='board-content-container' >
          <table className="table target-table border mb-0">
            <thead >
              <tr>
                <th className='text-muted font-weight-normal' scope="col">Question</th>
                <th className='text-muted font-weight-normal' scope="col">Answer options</th>
                {/* <th className='text-muted font-weight-normal' scope="col">Status</th> */}
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {data.map((screening, index) =>
              (
                <tr key={index}>
                  <th scope="row">
                    <div className='d-flex align-items-center h-100'>
                      <span>
                        <p className='text-indigo-dark small strong p-0 m-0' >{screening.name}</p>
                        <p className='regular strong text-primary p-0 m-0' >{screening.text}</p>
                      </span>
                    </div>
                  </th>
                  <td className='text-left align-middle' width={"40%"} >
                    <div className='d-flex flex-row flex-wrap pb-2'>
                      {screening.answers.map((answer, index) => (
                        <p key={index} className='text-nowrap text-truncate small strong badge px-2 py-1 mb-1 mr-1' >{answer.text ? answer.text : `${answer.start} to ${answer.end}`}</p>
                      ))}
                    </div>
                  </td>
                  {/* <td className='text-left align-middle p-0' width={"10%"}>
                    <div className='d-flex row justify-content-start align-items-center px-4' >
                      <Switch className="mr-2 table-switch" />
                      <span>Active</span>
                    </div>
                  </td> */}
                  <td className='text-right' width={"10%"} />
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }
    </article>
  )
}