import { Icon } from '../../../shared/Icon/Icon';

interface Props {
  fieldworkMetricsData: { value: string; code: string; text: string; icon: string; }[]
  dashboardData: TODO
}

export const FieldworkMetrics = ({ fieldworkMetricsData, dashboardData }: Props) => {

  return (
    <article className='nav-board d-flex flex-column bg-white answer-layout p-5'>
      <h6 className='stronger mb-4'>Fieldwork metrics</h6>
      <div className='d-flex row fieldwork-metrics'>
        {fieldworkMetricsData.map((el: any, key: any) => {
          return (
            <div className='col-lg col-12 w-100 mb-2 item' key={key}>
              <div className='d-flex align-items-center border rounded w-100 p-3' >
                <div className='p-2 rounded mr-3' style={{ backgroundColor: "rgba(23, 33, 71, 0.05)" }}>
                  <Icon type={el.icon} />
                </div>
                <div style={{ width: "100%" }}>
                  <div className='d-flex justify-content-between mb-1'>
                    <span className='m-0 strong medium'>{el.text}</span>
                    {el.code === "avgInterviewLengthSeconds" ?
                      <p className='m-0 strong medium'>{(dashboardData && Object.values(dashboardData?.completesOverTime).length > 0) ? dashboardData && dashboardData.avgInterviewLengthSeconds > 60 ? `${Math.floor(dashboardData.avgInterviewLengthSeconds / 60)}' ${dashboardData.avgInterviewLengthSeconds - Math.floor(dashboardData.avgInterviewLengthSeconds / 60) * 60}` : `${dashboardData && dashboardData.avgInterviewLengthSeconds}''` : "-"}</p>
                      :
                      el.code === "conversionRate" ?
                        <p className='m-0 strong medium'>{(dashboardData && Object.values(dashboardData?.completesOverTime).length > 0) ? el.value === 1 ? 100 + "%" : el.value + "%" : "-"}</p>
                        :
                        <p className='m-0 strong medium'>{el.value === 1 ? 100 : el.value}%</p>
                    }
                  </div>
                  {el.code !== "avgInterviewLengthSeconds" ?
                    <div style={{ display: "table", width: "100%" }}>
                      <div style={{ borderRadius: "8px", backgroundColor: "#edeef1" }}>
                        <div style={{ height: "4px", backgroundColor: "rgba(53, 136, 239)", borderRadius: "8px", width: `${el.value === 1 ? "100" : 0}%` }}></div>
                      </div>
                    </div>
                    :
                    null
                  }
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </article>
  )
}