import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { Audience } from '../../../interfaces/auddineceAccessInterfaces/auddineceAccessInterfaces'
import { getCurrentDate } from '../../../components/Audience/Details/Timeline/Timeline'

const initialState: Audience = {
  audienceStatus: "",
  bidId: "",
  completeAsap: true,
  containsPersonalData: false,
  cpi: 0,
  clientCPI: 0,
  currency: "GBP",
  createdById: "",
  createdByName: null,
  createdUtc: "",
  customerId: "",
  etag: "" || null,
  gatekeeperLiveUrl: "" || null,
  gatekeeperId: "",
  gatekeeperProjectNumber: "",
  gatekeeperTestUrl: "" || null,
  hasBeenLive: false,
  id: "",
  incidenceRate: undefined,
  interlockedQuotas: [],
  language: null,
  lastModifiedById: "",
  lastModifiedByName: "",
  lastModifiedUtc: "",
  launchDate: getCurrentDate(),
  lengthOfInterview: undefined,
  liveUrl: "",
  locale: "",
  lucidSurveyUrl: "" || null || undefined,
  country: null,
  name: "",
  panelType: "Multiple",
  projectId: "",
  projectManagerEmail: "",
  projectName: "",
  quantity: undefined,
  quotas: [],
  sampleProviders: [],
  screening: [],
  surveyType: "AdHoc",
  targetEndDate: "" || null,
  targetType: "Completes",
  testUrl: "",
  completes: 0,
  entrants: 0,
  actualIncidenceRate: 0
}

const audienceDataSlice = createSlice({
  name: 'audienceAccess',
  initialState,
  reducers: {
    createNewAudience: (state) => { Object.assign(state, initialState) },
    //@ts-ignore TODO
    setAudience(state, action: PayloadAction<Audience>) {
      return state = action.payload
    },
    editName(state, action: PayloadAction<string>) {
      return state = { ...state, name: action.payload }
    },
    editSurveyType(state, action: PayloadAction<string | null>) {
      return state = { ...state, surveyType: action.payload }
    },
    editCountry(state, action: PayloadAction<{
      name: string;
      isoCode: string;
    } | null>) {
      return state = { ...state, country: action.payload }
    },
    editLanguage(state, action: PayloadAction<{
      name: string;
      isoCode: string;
    } | null>) {
      return state = { ...state, language: action.payload }
    },
    editLOI(state, action: PayloadAction<number>) {
      return state = { ...state, lengthOfInterview: action.payload }
    },
    editRate(state, action: PayloadAction<number>) {
      return state = { ...state, incidenceRate: action.payload }
    },
    editStartDate(state, action: PayloadAction<string>) {
      return state = { ...state, launchDate: action.payload }
    },
    editCpi(state, action: PayloadAction<number>) {
      return state = { ...state, cpi: action.payload }
    },
    editClientCpi(state, action: PayloadAction<number>) {
      return state = { ...state, clientCPI: action.payload }
    },
    editPanelType(state, action: PayloadAction<string>) {
      return state = { ...state, panelType: action.payload }
    },
    editPII(state, action: PayloadAction<TODO>) {
      return state = { ...state, containsPersonalData: action.payload }
    },
    editLiveUrl(state, action: PayloadAction<string>) {
      return state = { ...state, liveUrl: action.payload }
    },
    editTestUrl(state, action: PayloadAction<string>) {
      return state = { ...state, testUrl: action.payload }
    },
    editTargetCompletes(state, action: PayloadAction<number>) {
      return state = { ...state, quantity: action.payload }
    },
    editEndDate(state, action: PayloadAction<string | null>) {
      return state = { ...state, targetEndDate: action.payload }
    },
    editAsap(state, action: PayloadAction<boolean>) {
      return state = { ...state, completeAsap: action.payload }
    },
    editAudienceStatus(state, action: PayloadAction<string>) {
      return state = { ...state, audienceStatus: action.payload }
    },
    editAudienceEtag(state, action: PayloadAction<string>) {
      return state = { ...state, etag: action.payload }
    },
    editTargetType(state, action: PayloadAction<string>) {
      return state = { ...state, targetType: action.payload }
    },
    editCurrencyType(state, action: PayloadAction<string>) {
      return state = { ...state, currency: action.payload }
    },
  }
})

export const {
  setAudience,
  editCpi,
  editPanelType,
  editPII,
  editLiveUrl,
  editTestUrl,
  editTargetCompletes,
  editEndDate,
  editAsap,
  editAudienceStatus,
  createNewAudience, editName, editSurveyType, editCountry, editLanguage, editLOI, editRate, editStartDate, editAudienceEtag, editTargetType, editClientCpi, editCurrencyType
} = audienceDataSlice.actions
export default audienceDataSlice.reducer