import { useDispatch } from 'react-redux'

import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { editLanguage, editCountry } from '../../../store/reducers/audienceAccessReducer/audienceAccessReducer'

interface Props {
  onHide: () => void,
  updatedMarket: { name: string, isoCode: string } | null
}

export const UpdateMarketModal = ({ onHide, updatedMarket }: Props) => {
  const dispatch = useDispatch()

  const handleUpdateMarket = () => {
    dispatch(editCountry(updatedMarket))
    dispatch(editLanguage(null))
    onHide()
  }

  return (
    <Dialog title={"Edit Market"} onClose={onHide}>
      <p>
        Making changes to the market will result in resetting all targeting settings. Are you sure you want to proceed?
      </p>
      <DialogActionsBar>
        <button
          className="k-button"
          onClick={onHide}
        >
          Cancel
        </button>
        <button
          className="k-button k-primary"
          onClick={handleUpdateMarket}
        >
          Continue
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}