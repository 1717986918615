import { useState } from 'react'
import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import { useDispatch, useSelector } from 'react-redux';
import { DateRangePicker, type SelectionRange } from '@progress/kendo-react-dateinputs';

import { Icon } from '../../../shared/Icon/Icon';
import type { RootState } from '../../../../store/reducers/rootReducer';
import { fetchGetRes } from '../../../../services/services';

type Props = {
  completesOverTime: []
  dashboardData: TODO
}

export const ChartReport = ({ completesOverTime, dashboardData }: Props) => {

  const [legendVisibility, setLegendVisibility] = useState<boolean>(true);
  const [dateRange, setDateRange] = useState<SelectionRange>()
  const chartData = [{ name: "Active", code: "active" }, { name: "Screen out", code: "screenedOuts" }, { name: "Quota full", code: "qualityFails" }, { name: "Drop out", code: "dropOuts" }, { name: "Quality fail", code: "qualityFails" }, { name: "Archived", code: "archived" }]
  const { gatekeeperId, gatekeeperProjectNumber } = useSelector((state: RootState) => state.audienceDataReducer);
  const dispatch = useDispatch()
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  const startDateInputSettings = {
    label: "Start Date",
    format: "dd/MM/yy",
    className: "report-DateRange"
  };

  const endDateInputSettings = {
    label: "End Date",
    format: "dd/MM/yy",
    className: "report-DateRange",
  };

  const onExportHandler = async () => {
    const exportRes: TODO = await fetchGetRes(`/co/gk/projects/export/${gatekeeperId}/${gatekeeperProjectNumber}`, token)
    if (exportRes && exportRes.status === 200) {
      dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "The project has been queued for export" } })
    } else {
      const exportJson = await exportRes.json()
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: exportJson.error ? exportJson.error : exportJson.message } })
    }
  }

  return (
    <article className='nav-board px-5 pt-5 pb-1 graph card-body d-flex flex-column answer-layout' style={{ backgroundColor: "rgba(22, 33, 48)" }}>
      <div className='d-flex justify-content-between align-items-center '>
        <div className='d-flex w-100 align-items-center justify-content-between h-32' >
          <p className='text-white m-0 fieldwork-dashboard-title'>Fieldwork progress</p>
          <div className='d-flex align-items-end h-32' >

            <div className="dropdown">
              <button className="btn btn-fieldwork btn-icon icon-r text-nowrap ml-3" type="button" id="dropdownMenuStatus" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Date range
                <Icon type="chevron-mini-down" fill='white' />
              </button>
              <div className="dropdown-menu dropdown-menu-right border-0" aria-labelledby="dropdownMenuStatus">
                <DateRangePicker
                  value={dateRange}
                  className="date-range"
                  startDateInputSettings={startDateInputSettings}
                  endDateInputSettings={endDateInputSettings}
                  onChange={(e) => {
                    setDateRange(e.value);
                  }} />
              </div>
            </div>
            <button type="button" className='btn btn-fieldwork btn-icon icon-l text-nowrap ml-3' onClick={() => onExportHandler()} >
              <Icon type="help-external" className="fill-white" />
              Export
            </button>
          </div>
        </div>
        <div>
          {dashboardData && Object.values(dashboardData.completesOverTime).length > 0 ?
            Object.keys(dashboardData.completesOverTime).length > 1 ? <div><span className='text-white'>{new Date(Object.keys(dashboardData.completesOverTime)[0]).toLocaleDateString('default', { month: 'long', day: 'numeric', year: 'numeric' })} - </span><span className='text-white'>{new Date(Object.keys(dashboardData.completesOverTime)[Object.keys(dashboardData.completesOverTime).length - 1]).toLocaleDateString('default', { month: 'long', day: 'numeric', year: 'numeric' })}</span></div> : <span className='text-white'>{new Date(Object.keys(dashboardData.completesOverTime)[0]).toLocaleDateString('default', { month: 'long', day: 'numeric', year: 'numeric' })}</span>
            :
            null
          }
        </div>
      </div>
      <div style={{ backgroundColor: "rgba(31, 43, 56)" }} className='p-5 position-relative rounded mt-4'>
        <p className='m-0' style={{ fontSize: "14px", color: "#d2d5d7" }} >Completes over time</p>
        {dashboardData && <p className='text-white' style={{ fontSize: "24px" }}>{Object.values(dashboardData.completesOverTime).reduce((a: TODO, b: TODO) => a + b, 0)} /{dashboardData && dashboardData.targetValue !== 0 ? dashboardData.targetValue : ""} {dashboardData && (dashboardData.targetValue !== null && dashboardData.targetValue !== 0) ? <span style={{ color: "rgba(142,149,156,255)" }}>{`(${(((dashboardData && Object.values(dashboardData.completesOverTime).reduce((a: TODO, b: TODO) => a + b, 0)) / dashboardData.targetValue) * 100).toFixed(0)}%) `}</span> : <span>∞</span>}</p>}
        {!dashboardData &&
          <div className='position-absolute' style={{ top: "50%", left: "50%", transform: "translate(-50%,-50%)", zIndex: 1, fontSize: "20px", color: "#8e959c" }}>No available data</div>
        }
        {/* <div className="spinner-border spinner-border-sm mr-2 position-absolute text-white" role="status" style={{ top: "50%", left: "50%", zIndex: 50000 }}>
           <span className="sr-only">Loading...</span>
        </div>} */}
        {dashboardData && Object.values(dashboardData.completesOverTime).length === 0 ? <p className='position-absolute' style={{ top: "50%", left: "50%", transform: "translate(-50%,-50%)", zIndex: 50000, fontSize: "20px", color: "#8e959c" }}>No available data</p> : null}
        <Chart transitions={false} style={{ height: '250px' }} onLegendItemClick={() => setLegendVisibility(true)}>
          <ChartArea background='transparent' height={250} />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              categories={completesOverTime ? completesOverTime.map((el: TODO) => { return el.date }) : []}
              labels={{ color: "#d2d5d7", margin: 10 }}
            />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem visible={dashboardData && Object.values(dashboardData.completesOverTime).length > 0 ? true : false} labels={{ color: "#e2e4e5", step: 2 }} majorGridLines={{ color: "#283641", step: 2 }} />
          </ChartValueAxis>
          <ChartSeries>
            <ChartSeriesItem
              type="area"
              name='Number of completes'
              data={completesOverTime ? completesOverTime.map((el: TODO) => { return el.value }) : ["3"]}
              markers={{ visible: false }}
              line={{ style: "step", width: 2, color: "#83E9CD" }}
              color={"rgba(131, 233, 205, 0.2)"}
              visible={legendVisibility}
            />
          </ChartSeries>
          <ChartLegend visible={dashboardData && Object.values(dashboardData.completesOverTime).length > 0 ? true : false} position={"bottom"} orientation={"horizontal"} labels={{ color: "#e2e4e5" }} />
        </Chart>
      </div>
      <div className='d-flex mt-4' style={{ gap: "24px" }}>
        {chartData.map((el: TODO, key: TODO) => {
          return (
            <div style={{ backgroundColor: "rgba(31, 43, 56)" }} className='px-4 py-3 mb-4 rounded w-100' key={key}>
              <span style={{ color: "rgba(255, 255, 255, 0.6)" }} className='m-0 medium strong'>{el.name}</span>
              <p className='text-white mb-0' style={{ fontSize: "16px" }}>{dashboardData?.[el.code]}</p>
            </div>
          )
        })}
      </div>
    </article >
  )
}
