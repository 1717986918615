
export default (props) => {
  let disabled
  if (props.tab === 'topline') {
    disabled = false
  }
  else if (props.tab === 'crosstab') {
    if (props.user.plan === 'basic') {
      disabled = true
    }
    else {
      disabled = false
    }
  }
  else if (props.tab === 'qt' || props.tab === 'data' || props.tab === 'dig') {
    if (props.user.plan !== 'professional_monthly' && props.user.plan !== 'professional_yearly') {
      disabled = true
    }
    else {
      disabled = false
    }
  } else {
    disabled = false
  }
  if (props.user.isSubAccount) {
    disabled = false
  }
  return (
    disabled
  )
}