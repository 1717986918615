import React, { Fragment } from 'react';

import { DropdownItem } from "../DropdownButton"
import { Icon } from '../../../shared/Icon/Icon';

interface Props {
  text?: string
  items?: any[]
  textField?: string
  onItemClick?: (e: any) => void
  renderItem?: (item: DropdownItem) => void
}

export const DropdownButtonRenderedItems = ({ text, items, textField, renderItem, onItemClick }: Props) => {
  let renderItems: any = null
  const itemTextField: string = textField ? textField : 'text'

  if (items && items.length > 0) {
    renderItems = React.Children.toArray(
      //@ts-ignore TODO
      items.map((item, key) => {
        if (item[itemTextField] === 'separator') {
          // display line between items
          return (
            <div
              style={{ height: '1px' }}
              className="w-100 my-1 bg-light"
            />
          )
        } else if (renderItem) {
          // Display custom jsx for the items
          return renderItem({ ...item, key: key })
        } else if ((item[itemTextField]?.includes('Delete') || item[itemTextField]?.includes('Disable')) && !item.ignoreDeleteDisable) {
          // Display Delete/Disable item with a separator above it
          return (
            <Fragment>
              <div
                style={{ height: '1px' }}
                className="w-100 my-1 bg-light" />
              <button
                type="button"
                className="dropdown-item text-danger px-3"
                disabled={item.disabled}
                onClick={onItemClick ? (e) => onItemClick({ ...e, item: item }) : () => { }}>
                <span>{item[itemTextField]}</span>
              </button>
            </Fragment>
          )
        } else {
          // Display default button
          return (
            <button
              type="button"
              className="d-flex align-items-center dropdown-item px-4"
              disabled={item.disabled}
              onClick={onItemClick ? (e) => onItemClick({ ...e, item: item }) : () => { }}>
              <div className="d-flex align-items-center">
                {
                  text &&

                  <Icon type="checkmark" className={`mr-2 fill-primary ${item[itemTextField] !== text ? 'invisible' : ''}`} />
                }
                <span className='text-truncate'>{item[itemTextField]}</span>
              </div>
            </button>
          )
        }
      })
    )
  }

  return renderItems
}

