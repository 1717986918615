import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'

import { editAudienceEtag, editAudienceStatus } from '../../../store/reducers/audienceAccessReducer/audienceAccessReducer'
import type { editStatus } from '../../../interfaces/auddineceAccessInterfaces/auddineceAccessInterfaces'
import type { RootState } from '../../../store/reducers/rootReducer'
import { fetchPutResOrJson as editStatusPutRequest } from '../../../services/services'

interface Props {
  onHide: () => void,
  selectedStatus: string,
}

export const UpdateStatusModal = ({ onHide, selectedStatus }: Props) => {
  const { etag } = useSelector((state: RootState) => state.audienceDataReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const params: { id: string, name: string } = useParams()
  const dispatch = useDispatch()
  let editStatusObj: editStatus = {
    audienceStatus: "",
    etag: ""
  }

  const handleUpdateStatus = () => {
    editStatusObj = { audienceStatus: selectedStatus, etag: etag }
    editStatusPutRequest(`au/a/projects/${params.name}/audiences/${params.id}/status`, token, editStatusObj)
      .then(async (res: TODO) => {
        const { etag } = await res.json()
        dispatch(editAudienceEtag(etag))
        if (res.ok) {
          dispatch(editAudienceStatus(selectedStatus))
          onHide()
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Status updated successfully' } })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.status !== 200 ? res.title : res.message } });
        }
      })
  }

  return (
    <Dialog title={"Update Status"} onClose={onHide}>
      <p style={{ margin: "8px" }}>
        Please note that modifying the status may render certain inputs uneditable. <br /> Do you want to proceed?
      </p>
      <DialogActionsBar>
        <button
          type='button'
          className="k-button"
          onClick={onHide}
        >
          Cancel
        </button>
        <button
          type='button'
          className="k-button k-primary"
          onClick={handleUpdateStatus}
        >
          Continue
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}