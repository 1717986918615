import { useEffect } from "react"
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"

import usePrevious from "./usePrevious";
import type { RootState } from "../../../store/reducers/rootReducer";
import tabs from '../../Analysis/Analyze/components/dashboard/main/analysis/tabsData.json';
import { fetchGetJson as loadWorkingSession } from './../../../services/services';

type ActiveTab = { id: string }

type OptionsData = { [x: string]: TODO }

export const useLoadAnalysisWorkingSession = (activeTabs: ActiveTab[], optionsData: OptionsData, didMountSession: boolean | null, setOptionsData: (val: OptionsData) => void, setTabSelected: (val: number) => void, setDidMountSession: (val: boolean | null) => void, setIsSessionLoaded: (val: boolean) => void) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const params: { dataset: string } = useParams()
  const prevDidMountSession = usePrevious(didMountSession)

  useEffect(() => {
    if (didMountSession) {
      if (params?.dataset) {
        setDidMountSession(null)
        loadWorkingSession(`workingsessions/${params.dataset}`, token)
          .then((data: TODO) => {
            if (!data.error) {
              if (data.selectedTab === 8) {
                setTabSelected(8)
              } else {
                const activeTab = activeTabs.find((activeTab: any) => activeTab.id === tabs[data.selectedTab].id)
                if (activeTab) {
                  setTabSelected(activeTabs.indexOf(activeTab))
                } else {
                  setTabSelected(0)
                }
              }
              setIsSessionLoaded(true)
              setOptionsData({ ...optionsData, didMount: true })
              data.scriptState ? dispatch({ type: "UPDATE_INITIAL_SCRIPT_STATE", payload: data.scriptState }) : dispatch({ type: "SCRIPT_SET_INITIAL_STATE" })
              dispatch({ type: "UPDATE_INITIAL_TOPLINE_STATE", payload: data.toplineState })
              dispatch({ type: "UPDATE_INITIAL_STATE", payload: data.xtState })
              dispatch({ type: "UPDATE_INITIAL_CHART_STATE", payload: data.chartState })
              dispatch({ type: "UPDATE_INITIAL_DIG_STATE", payload: data.digState })
              dispatch({ type: "UPDATE_INITIAL_QTABLE_STATE", payload: data.qTableState })
              dispatch({ type: "UPDATE_INITIAL_RAW_DATA_STATE", payload: data.dataState })
              dispatch({ type: "UPDATE_INITIAL_REPORT_DATA_STATE", payload: data.reportState })
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Latest progress has been loaded successfully' } })
              if (data.analysisScriptingState?.data) {
                dispatch({ type: "LOAD_ANALYSIS_SCRIPTING_STATE", payload: data.analysisScriptingState })
              } else {
                dispatch({ type: "SET_INITIAL_ANALYSIS_SCRIPTING_DATA" })
              }
            } else {
              setTabSelected(0)
              setOptionsData({ ...optionsData, didMount: true })
              dispatch({ type: "TOPLINE_SET_INITIAL_STATE" })
              dispatch({ type: "SET_INITIAL_STATE" })
              dispatch({ type: "CHART_SET_INITIAL_STATE" })
              dispatch({ type: "DIG_SET_INITIAL_STATE" })
              dispatch({ type: "QTABLE_SET_INITIAL_STATE" })
              dispatch({ type: "RAW_DATA_SET_INITIAL_STATE" })
              dispatch({ type: "SCRIPT_SET_INITIAL_STATE" })
              dispatch({ type: "REPORT_CLEAR_DATA" })
              dispatch({ type: 'SET_INITIAL_ANALYSIS_SCRIPTING_DATA' })
            }
          })
      }
    } else if (didMountSession === false && didMountSession !== prevDidMountSession) {
      setDidMountSession(false)
      setTabSelected(0)
      setOptionsData({ ...optionsData, didMount: true })
      dispatch({ type: "TOPLINE_SET_INITIAL_STATE" })
      dispatch({ type: "SET_INITIAL_STATE" })
      dispatch({ type: "CHART_SET_INITIAL_STATE" })
      dispatch({ type: "DIG_SET_INITIAL_STATE" })
      dispatch({ type: "QTABLE_SET_INITIAL_STATE" })
      dispatch({ type: "RAW_DATA_SET_INITIAL_STATE" })
      dispatch({ type: "SCRIPT_SET_INITIAL_STATE" })
      dispatch({ type: 'UPDATE_INITIAL_REPORT_DATA_STATE' })
      dispatch({ type: 'SET_INITIAL_ANALYSIS_SCRIPTING_DATA' })
    }
  }, [didMountSession, prevDidMountSession])
}