import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'

interface Props {
  onHide: () => void,
  handlePreviewSubmit: Function,
  modalData: ModalData,
  handleChange: Function,
  error: any
}
interface ModalData {
  respondentIds: string[],
  type: string
}
export const ReconcileModal = ({ onHide, handlePreviewSubmit, modalData, handleChange, error }: Props) => {

  return (
    <Dialog width={"600px"} className='reconcile-modal' contentStyle={{ padding: 0, fontSize: 14 }} title={"New reconciliation"} onClose={onHide}>
      <form onSubmit={(e) => handlePreviewSubmit(e)} >
        <div className='p-4' >
          <p className='p-0 m-0 text-start' ><label className='p-0 m-0 strong' htmlFor="reconcileModal">Walr IDs</label></p>
          <p className='p-0 mb-2 text-start text-muted' ><label className='medium m-0' htmlFor="reconcileModal">Upload all the accept Walr IDs, any IDs not included will be removed.</label></p>
          <div className="details-input d-flex flex-column justify-content-center w-100 mb-3 px-0">
            <div className='d-flex flex-row align-items-center'>
              <div className="form-check mt-0 form-check-inline cursor-pointer">
                <input className='cursor-pointer' id="Append" type="radio" name="type" checked={modalData.type === "Append"} onChange={(e) => handleChange(e)} value="Append"
                />
                <label className="d-flex align-items-center cursor-pointer m-0 pl-2 strong medium" htmlFor="Append">Append to previous reconciliation</label>
              </div>
              <div className="form-check mt-0 form-check-inline cursor-pointer">
                <input className='cursor-pointer' id="Overwrite" type="radio" checked={modalData.type === "Overwrite"} onChange={(e) => handleChange(e)} name="type" value="Overwrite"
                />
                <label className="d-flex align-items-center cursor-pointer m-0 pl-2 strong medium" htmlFor="Overwrite">Overwrite previous reconciliation</label>
              </div>
            </div>
            {error?.type &&
              <div className="text-danger mt-1">
                <small>This input is required.</small>
              </div>}
          </div>
          <textarea
            className='w-100 form-control'
            id="reconcileModal"
            name="respondentIds"
            placeholder='Add IDs one per line'
            rows={5}
            cols={50}
            onChange={(e) => handleChange(e)}>
          </textarea>
          {error?.respondentIds &&
            <div className="text-danger">
              <small>This field is required.</small>
            </div>}
        </div>
        <DialogActionsBar>
          <button
            className="btn btn-secondary mr-2"
            onClick={onHide}
          >
            Cancel
          </button>
          <button
            type='submit'
            className="btn btn-primary"
          >
            Preview
          </button>
        </DialogActionsBar>
      </form>
    </Dialog >
  )
}