import { useState } from 'react'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Tooltip } from '@progress/kendo-react-tooltip'

import { Icon } from '../../shared/Icon/Icon'
import type { GkSuppliersData } from '../../../interfaces/gateKeeperInterfaces/gateKeeperInterfaces'

interface Props {
  onHide: Function,
  addNewSupplier: () => void,
  suppliersData: GkSuppliersData[],
  isLoading: boolean
}

export const GateKeeperSupplierModal = ({ suppliersData, onHide, addNewSupplier, isLoading }: Props) => {
  const [searchInput, setSearchInput] = useState<string>("")

  return (
    <Dialog width={"800px"} height={"60%"} contentStyle={{ padding: 0, fontSize: 13 }} title={"Suppliers"} onClose={() => onHide("Suppliers")}>
      {isLoading ?
        <div className='h-75 d-flex align-items-center justify-content-center'><span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true" /></div>
        :
        suppliersData ?
          <div className='overflow-hidden h-100 d-flex flex-column'>
            <div className='p-4'><input type="text" value={searchInput} className="form-control search-input w-100" placeholder="Search" aria-label="Search" onChange={(e) => setSearchInput(e.target.value)} /></div>
            <div className='overflow-auto h-100 d-flex flex-column' >
              {suppliersData.filter(supplier => supplier.name.toLowerCase().includes(searchInput.toLowerCase())).map((item: TODO, index: number) => (
                <div key={index} className="border rounded mx-4 mb-2">
                  <div className="accordion" id="accordionSupplier">
                    <div className="accordion-item">
                      <div className="accordion-header w-100" id={`heading${index}`} data-toggle="collapse" data-target={`#main${index}`} aria-expanded="false" aria-controls="main">
                        <div className='d-flex align-items-center justify-content-between px-2 cursor-pointer w-100'>
                          <p className='text-primary strong m-0 pl-1 py-2' >{item.name}</p>
                          <div className='d-flex justify-content-center align-items-center row pr-3' >
                            <span className='text-muted small'>{`Platform_ID: ${item.platformId}`}</span>
                            <Icon type="chevron-mini-down-alt" className="submenu" />
                          </div>
                        </div>
                      </div>
                      <div id={`main${index}`} className="collapse border-top p-4" aria-labelledby={`heading${index}`} data-parent="#accordionSupplier">
                        <div className='d-flex flex-row align-items-center mb-1'>
                          <p className='w-25 strong m-0'>Complete</p>
                          <p className='w-75 m-0'><input type='text' className='form-control medium' value={item.completeUrl} onFocus={e => e.target.select()} /></p>
                        </div>
                        <div className='d-flex flex-row align-items-center mb-1'>
                          <p className='w-25 strong m-0'>Over quota</p>
                          <p className='w-75 m-0'><input type='text' className='form-control medium' value={item.quotaOutUrl} onFocus={e => e.target.select()} /></p>
                        </div>
                        <div className='d-flex flex-row align-items-center mb-1'>
                          <p className='w-25 strong m-0'>Quality termination</p>
                          <p className='w-75 m-0'><input type='text' className='form-control medium' value={item.qualityFailUrl} onFocus={e => e.target.select()} /></p>
                        </div>
                        <div className='d-flex flex-row align-items-center'>
                          <p className='w-25 strong m-0'>Screen out</p>
                          <p className='w-75 m-0'><input type='text' className='form-control medium' value={item.screenOutUrl} onFocus={e => e.target.select()} /></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          :
          <div className='h-100 d-flex align-items-center justify-content-center text-muted'><p>No suppliers found</p></div>
      }
      <DialogActionsBar>
        <div className='d-flex w-100 justify-content-end align-items-center'>
          <Tooltip anchorElement="target" position="top">
            <span title='Use Platform = 0 for supplier agnostic redirect.' className='btn btn-shadow p-1 d-flex align-items-center mr-2'><Icon type='info-tooltip' className='pe-none' /> </span>
          </Tooltip>
          <button type='submit' className="btn btn-primary btn-icon icon-l" onClick={addNewSupplier} >
            <Icon type="add" /> Add new supplier
          </button>
        </div>
      </DialogActionsBar>
    </Dialog >
  )
}