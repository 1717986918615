import React from 'react';

export const FullHeightSpinner = () => {
  return (
    <div className="d-flex flex-grow-1 justify-content-center align-items-center align-self-center h-100">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}