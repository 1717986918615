import { useSelector } from 'react-redux';

import { RootState } from '../../../store/reducers/rootReducer';
import { ManageTarget } from './ManageTarget/ManageTarget';
import { TagretingQuota } from './Quota/Quota'
import { TagretingScreening } from './Screening/Screening'

export const AudienceTargeting = ({ setShowCustomAudienceModal }: { setShowCustomAudienceModal: Function }) => {
  const data = useSelector((state: RootState) => state.audienceDataReducer);

  return (

    <div className='board-container d-flex justify-content-center flex-column w-100' >
      {
        // false ? (<ManageTarget toggleCustomAudienceModal={toggleCustomAudienceModal} />) :
        (data.screening.length < 1 && data.quotas.length < 1) ? (<ManageTarget toggleCustomAudienceModal={() => setShowCustomAudienceModal({ show: true, editMode: false })} />) :
          (
            <>
              <div >
                <TagretingScreening data={data.screening} />
                <TagretingQuota data={data} />
              </div>
              <footer className='p-4 bg-white'>
                <div className='d-flex justify-content-end board-container'>
                  {/* <button type='submit' className='rounded border py-2 px-3 mr-2 bg-primary text-white' >Add quota</button> */}
                  <button type='submit' className='btn btn-primary' onClick={() => setShowCustomAudienceModal({ show: true, editMode: true })} >Edit targeting</button>
                </div>
              </footer>
            </>
          )
      }
    </div>
  )
}