export interface ScreeningAnswers {
  id?: number,
  text?: string,
  start?: number,
  end?: number
}
export interface QuotaAnswers {
  id?: number,
  text?: string,
  start?: number,
  end?: number
}
export interface QuotaTargets {
  id: number,
  targetCompletes: number,
  completes: number,
  completesRemaining: number,
  entrants: number,
  incidenceRate: number,
  answers: QuotaAnswers[]
}

export interface Screening {
  questionId: number,
  name: string,
  text: string,
  isInclude: boolean,
  answers: ScreeningAnswers[]
}
export interface Quotas {
  questionId: number,
  name: string,
  text: string,
  targets: QuotaTargets[]
}

export interface SempleProvider {
  providerId: string,
  providerName: string
}

export interface Audience {
  audienceStatus: string;
  bidId: string | null;
  completeAsap: boolean;
  containsPersonalData: boolean;
  cpi?: number;
  clientCPI?: number;
  currency: string | null;
  createdById: string;
  createdByName: string | null;
  createdUtc: string;
  customerId: string;
  etag: string | null;
  gatekeeperLiveUrl: string | null;
  gatekeeperId: string;
  gatekeeperProjectNumber: string;
  gatekeeperTestUrl: string | null;
  hasBeenLive: boolean;
  id: string;
  incidenceRate?: number;
  interlockedQuotas: [];
  language: {
    name: string;
    isoCode: string;
  } | null;
  lastModifiedById: string;
  lastModifiedByName: string;
  lastModifiedUtc: string;
  launchDate: string;
  lengthOfInterview?: number;
  liveUrl: string;
  locale: string;
  lucidSurveyUrl?: string | null;
  country: {
    name: string;
    isoCode: string;
  } | null;
  name: string;
  panelType: string;
  projectId: string;
  projectManagerEmail: string;
  projectName: string;
  quantity?: number;
  quotas: Quotas[];
  sampleProviders: SempleProvider[];
  screening: Screening[];
  surveyType: string | null;
  targetEndDate: string | null;
  targetType: string;
  testUrl: string;
  message?: string;
  completes: number;
  entrants: number;
  actualIncidenceRate: number;
}

export interface EditAudience {
  name: string,
  surveyType: string,
  country: {
    name: string;
    isoCode: string;
  },
  language: string,
  quantity: string,
  incidenceRate: string,
  lengthOfInterview: string,
  liveUrl: string,
  testUrl: string,
  containsPersonalData: boolean,
  completeAsap: string,
  panelType: string,
  targetType: string,
  cpi: number,
  clientCPI: number,
  currency: string,
  screening: Screening[],
  quotas: Quotas[],
  interlockedQuotas: [],
  launchDate: string,
  targetEndDate: string,
  etag: string | null,
}
export interface NewAudience {
  name: string,
  surveyType: string,
  country: {
    name: string;
    isoCode: string;
  },
  language: string,
  quantity: string,
  incidenceRate: string,
  lengthOfInterview: string,
  liveUrl: string,
  testUrl: string,
  containsPersonalData: boolean,
  screening: [],
  quotas: [],
  interlockedQuotas: [],
  launchDate: string,
  targetEndDate: string,
  completeAsap: boolean,
  panelType: string,
  cpi: number,
  targetType: string,
  clientCPI: number,
  currency: string
}

export interface editStatus {
  audienceStatus: string;
  etag: string | null
}

export const AudienceOverviewTabs = ["Details", "Targeting", "Reports", "Reconcile"] as const;
export type AudienceOverviewTabsType = (typeof AudienceOverviewTabs)[number];