import { Icon } from '../../../shared/Icon/Icon'

export const ManageTarget = ({ toggleCustomAudienceModal }: { toggleCustomAudienceModal: () => void }) => {
  return (
    <article className='container-target-manage nav-board bg-white d-flex justify-content-center align-items-center overflow-auto flex-column w-100'>
      <div className='content-container d-flex flex-column align-items-center justify-content-center mb-4' >
        <div className='inner mb-8'>
          <div className='icon d-flex align-items-center justify-content-center'><Icon type="target-audience" className='target-icon' /></div>
          <div className='circle'></div>
          <div className='circle'></div>
          <div className='circle'></div>
          <div className='circle'></div>
        </div>
        <p className='target-title stronger mb-2' >Add and manage your targeting</p>
        <p className='target-text text-primary' >This is where you'll add and manage your screening and quota questions. Reach national representative, general population or build your own custom audience.</p>
      </div>
      <div className='card-container d-flex flex-row w-100  justify-content-center mt-4' >
        <div className='card-container d-flex w-100 align-items-center justify-content-center' >
          <div className='target-card w-30 d-flex flex-column align-items-start bg-white justify-content-between p-6'>
            <div>
              <h6 className='text-primary stronger mb-2'>National representative</h6>
              <p className='text-primary text-muted'>Create a well-balanced sample from our panel, considering factors such as age, gender, social grade, education, and region for accurate representation.</p>
            </div>
            <button disabled className="btn dropdown-toggle text-white bg-primary strong" type="button">
              Use Nat Rep
            </button>
          </div>
          <div className='target-card w-30 d-flex flex-column align-items-start bg-white justify-content-between p-6'>
            <div>
              <h6 className='text-primary stronger mb-2'>General population</h6>
              <p className='text-primary text-muted'>Generate a representative sample of the 'General Population' for your market research, ensuring comprehensive insights across diverse demographics.</p>
            </div>
            <button disabled className="btn dropdown-toggle text-white bg-primary strong" type="button">
              Use Gen Pop
            </button>
          </div>
          <div className='target-card w-30 d-flex flex-column align-items-start bg-white justify-content-between p-6'>
            <div>
              <h6 className='text-primary stronger mb-2'>Custom audience</h6>
              <p className='text-primary text-muted'>Build your own audience by utilizing numerous of targeting variables.</p>
            </div>
            <button onClick={() => toggleCustomAudienceModal()} className="btn dropdown-toggle text-white bg-primary strong" type="button">
              Build custom audience
            </button>
          </div>
        </div>
      </div>
    </article>
  )
}