import { useEffect, useState, useRef, Fragment } from 'react';
import Editor from "@monaco-editor/react";
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, DropDownButton } from '@progress/kendo-react-buttons';

import items from '../exportItems.json';
import { ExportModal } from '../../ExportModal/ExportModal';
import { ExportActionItem } from '../../../../shared/ExportActionItem/ExportActionItem';
import returnActionsData from '../../../../shared/helpers/returnActionsData/returnActionsData';
import returnUpdatedAnalysisActionItems from '../../../../shared/helpers/returnUpdatedAnalysisActionItems/returnUpdatedAnalysisActionItems';
import type { RootState } from '../../../../../../../../store/reducers/rootReducer';
import type { User } from '../../../../../../../../interfaces/userInterface/userInterface';
import usePrevious from '../../../../../../../shared/customHooks/usePrevious';

interface Props {
  user: User
  token: string
  datasetId: string
  datasetName: string
  datasetType: string
  optionsData: TODO
  analysisFunctionalities: TODO
  onAutoSaveHandler: (state: TODO) => void
}

interface ShowExportModal {
  show: boolean
  type: string | null
  action: string | null
  extension: string | null
}

export const ScriptTabContent = ({ onAutoSaveHandler, datasetId, datasetName, datasetType, optionsData, user, token, analysisFunctionalities }: Props) => {
  const { state } = useSelector((theState: RootState) => (theState.scriptStateReducer));
  const minimized = useSelector((state: TODO) => state.userSettingsReducer.minimize);
  // const { selectedSession } = useSelector(theState => ({ ...theState.breadcrumbStateReducer }))
  const [showExportModal, setShowExportModal] = useState<ShowExportModal>({ show: false, action: null, type: null, extension: null })
  const [editorValue, setEditorValue] = useState<string>('')
  const [editorTheme, setEditorTheme] = useState<string>('vs-light')
  const dispatch = useDispatch();
  const history = useHistory()
  const prevStateXml = usePrevious(state?.xml)
  const prevStateTheme = usePrevious(state?.theme)
  const editorRef = useRef(null);

  useEffect(() => {
    if (state.xml && state.theme) {
      if (state.xml !== prevStateXml && state.theme !== prevStateTheme) {
        setEditorValue(state.xml)
        setEditorTheme(state.theme)
      }
    }
  }, [state, prevStateXml, prevStateTheme])

  const updatedItems = returnUpdatedAnalysisActionItems(analysisFunctionalities, items, datasetType, true)

  // const sessionDropdownItems = returnSessionDropdownItems(selectedSession)

  const actionItemRender = (props: { item: { action: string, actionName: string, extension: string, icon: string } }) => {
    return (
      <ExportActionItem
        user={user}
        props={props}
        state={state}
        showWizardModal={() => { }}
        showExportModal={() => setShowExportModal({ show: true, action: props.item.action, type: props.item.actionName, extension: props.item.extension })}
        tab='script' />
    )
  }

  const handleEditorChange = (value: TODO) => {
    setEditorValue(value)
  }

  const onThemeChangeHandler = (e: { item: { theme: string, name: string } }) => {
    setEditorTheme(e.item.theme)
  }

  const formatXml = (xml: TODO) => {
    let formatted = '';
    let indent = '';
    xml.split(/>\s*</).forEach((node: string) => {
      if (node.match(/^\/\w/)) indent = indent.substring('\t'.length);
      formatted += `${indent}<${node}>\r\n`;
      if (node.match(/^<?\w[^>]*[^/]$/)) indent += '\t';
    });
    return formatted.substring(1, formatted.length - 3);
  }

  const onSaveChangesHandler = () => {
    setEditorValue(formatXml(editorValue))
    onAutoSaveHandler({ xml: formatXml(editorValue), theme: editorTheme })
    dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The latest changes have been saved successfully' } })
  }

  useEffect(() => {
    const editor = editorRef.current as unknown as HTMLElement;
    const handlePaste = (event: ClipboardEvent) => {
      event.preventDefault();
      setEditorValue(prev => formatXml(prev))
    }

    if (editor) {
      editor.addEventListener('paste', (event: ClipboardEvent) => handlePaste(event));
    }

    return () => {
      if (editor) {
        editor.removeEventListener('paste', (event: ClipboardEvent) => handlePaste(event));
      }
    };
  }, []);

  return (
    <Fragment>
      {
        showExportModal?.show &&
        <ExportModal
          token={token}
          state={state}
          tab={'script'}
          history={history}
          datasetId={datasetId}
          editorValue={editorValue}
          datasetName={datasetName}
          datasetType={datasetType}
          showExportModal={showExportModal}
          onExportHandler={returnActionsData}
          options={{ exportOptions: optionsData.exportOptions, values: optionsData.dropdownValues }}
          onHide={() => { setShowExportModal({ ...showExportModal, show: false }); dispatch({ type: 'CLOSE_EXPORT_MODAL' }) }} />
      }
      <div className={"justify-content-between flex-wrap d-flex border-left pr-1"}>
        <div className="btn-group m-2 analysis-actions">
          <Button
            className={"btn btn-outline-analyze px-2"}
            onClick={onSaveChangesHandler}>Save changes
          </Button>
          <Button
            className={"btn btn-outline-analyze px-2"}
            onClick={() => setEditorValue(formatXml(editorValue))}>Format code
          </Button>
          <div className="btn-group">
            <DropDownButton
              text={"Theme"}
              textField="name"
              icon="fas fa fa-caret-down"
              className='analyze-actions-button'
              buttonClass={"btn btn-outline-analyze rounded-left-0 d-flex flex-row-reverse px-2"}
              items={[{ theme: 'vs-dark', name: 'Dark', icon: 'fas fa-moon' }, { theme: 'vs-light', name: 'Light', icon: 'far fa-moon' }]}
              itemRender={(props: { item: { theme: string, name: string, icon: string } }) => (
                <div className="p-1 d-flex align-items-center">
                  <i className={`${props.item.icon} mr-2`} /><span>{props.item.name}</span>
                </div>
              )}
              onItemClick={onThemeChangeHandler}
            />
          </div>
        </div>

        <div className="btn-group m-2 analysis-actions">
          {/* <div className="btn-group">
            <DropDownButton
              text={"Sessions"}
              textField="actionName"
              icon="fas fa fa-caret-down"
              className='analyze-actions-button'
              buttonClass={`btn btn-outline-analyze rounded-right-0 d-flex flex-row-reverse px-2`}
              items={sessionDropdownItems}
              onItemClick={(e) => onSaveActionHandler(e, state)}
            />
          </div> */}
          <div className="btn-group">
            <DropDownButton
              text={"Export"}
              icon="fas fa fa-caret-down"
              className='analyze-actions-button'
              buttonClass={"btn btn-outline-analyze rounded-left-0 d-flex flex-row-reverse px-2"}
              items={updatedItems}
              itemRender={actionItemRender}
              popupSettings={{ popupClass: 'actions-dropdown' }}
            />
          </div>
        </div>
      </div>
      <div ref={editorRef} className={minimized ? "analysis-main-container mr-3 border-table-area xml-editor p-4" : "border-table-area xml-editor p-4"}>
        <Editor
          theme={editorTheme}
          defaultLanguage="xml"
          defaultValue={state.xml}
          value={editorValue}
          onChange={handleEditorChange}
        />
      </div>
    </Fragment>
  )
}