import { useEffect, useMemo, useRef } from "react";

function debounce(func, timeout) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export const useDebounce = (callback, timeout) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      // eslint-disable-next-line no-unused-expressions
      ref.current?.();
    };

    return debounce(func, timeout);
  }, [timeout]);

  return debouncedCallback;
};