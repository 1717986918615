import { Icon } from '../../../shared/Icon/Icon'

interface Props {
  reconciliationSummary: any,
  isLoading: boolean
}

export const SupplierAccordion = ({ reconciliationSummary, isLoading }: Props) => {
  return (
    <div className="table target-table border-0 bg-white mb-0 mt-3 rounded-0 table-shadow">
      <div className="accordion" id="accordionSupplier">
        <div className="accordion-item">
          <div className="accordion-header w-100" data-toggle="collapse" data-target={`#main`} aria-expanded="false" aria-controls="main">
            <div className='d-flex align-items-center justify-content-between pr-2 w-100'>
              <p style={{ width: "80%" }} className='text-primary strong m-0 p-3' >Supplier summary</p>
              <Icon type="chevron-mini-down-alt" className="submenu" />
            </div>
          </div>
          <div
            id={`main`}
            className="accordion-collapse collapse px-3 pb-4"
            aria-labelledby={`heading`}
            data-parent="#accordionSupplier">
            {reconciliationSummary ?
              <div className='border rounded-0 overflow-hidden' >
                <table className="w-100 mb-0 ">
                  <thead >
                    <tr>
                      <th className='text-muted font-weight-normal' scope="col" style={{ width: "60%" }}>Supplier</th>
                      <th className='text-muted font-weight-normal' scope="col">Reconciled to term</th>
                      <th className='text-muted font-weight-normal' scope="col">Total accepted completes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reconciliationSummary.map((item: any, index: number) => (
                      <tr key={index} className='medium border-top'>
                        <td className='text-left py-2 px-3' >{item.platform}</td>
                        <td className='text-left py-2 px-3'>{item.terminatedCount}</td>
                        <td className='text-left py-2 px-3' >{item.completedCount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div> :
              <div >
                {isLoading ?
                  <div className='d-flex align-items-center w-100 justify-content-center' ><span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span></div>
                  :
                  <div className='text-muted d-flex align-items-center justify-content-center' >No supplier found</div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}