export const AudienceTabs = ["All", "Pending", "Draft", "Closed", "Live", "Reconciled"] as const;
export type AudienceTabsType = (typeof AudienceTabs)[number];

export const AudienceStatus = ["Pending", "Live", "Closed", "Cancelled", "Reconciled", "Archived"] as const;
export type AudienceStatusType = (typeof AudienceStatus)[number];

export type Markets = {
  name: string,
  isoCode: string,
  languages: string[]
}

export type Market = {
  text: string
}

export type StatusCounts = {
  name: AudienceTabsType
  number: number
}

type ProjectProgress = {
  current: number;
  total: number;
  percentage: number
}

export type Audience = {
  audienceId: string;
  etag: string;
  name: string;
  status: AudienceTabsType;
  market: string;
  progress: ProjectProgress;
  loi: { actual: number, difference: number };
  incidenceRateStats: { actual: number, difference: number };
  dropOffPercentage: number;
  quotaFailedPercentage: number;
  cpi: number;
  surveyCloseDate: string;
  projectManagerEmail: string;
}

export type Projects = {
  id: string;
  projectName: string;
  progress: ProjectProgress;
  surveyClosestDate: string;
  averageDropOffPercentage: number;
  quotaFailedPercentage: number;
  averageCPI: number;
  loi: { actual: number, difference: number };
  incidenceRateStats: { actual: number, difference: number };
  markets: string[];
  audiences: Audience[]
}

export type AudiencesData = {
  continuationToken: string | null
  statusCounts: StatusCounts[]
  projects: Projects[]
}
