import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import JsxParser from 'react-jsx-parser';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Button, DropDownButton } from '@progress/kendo-react-buttons';

import items from '../exportItems.json';
import clearQuestionItems from './digClearItems.json';
import defaultOptions from '../../analysis/defaultOptionValues.json';
import { ExportModal } from '../../ExportModal/ExportModal';
import WeightWizard from '../../WeightWizard/WeightWizard';
import { OptionsDialog } from '../../OptionsDialog/OptionsDialog';
import { QuestionEditor } from '../../QuestionEditor/QuestionEditor';
import DroppableColumn from '../../../DroppableColumn/DroppableColumn';
import OptionsFilterBuilder from '../../OptionsFilterBuilder/OptionsFilterBuilder';
import { ZoomButtons } from '../../../../shared/ZoomButtons/ZoomButtons';
import { ExportActionItem } from '../../../../shared/ExportActionItem/ExportActionItem';
import { InProgressOverlay } from '../../../../../../../shared/InProgressOverlay/InProgressOverlay';
import UpgradePlanMessage from '../../../../../../../shared/UpgradePlanMessage/UpgradePlanMessage';
import returnActionsData from '../../../../shared/helpers/returnActionsData/returnActionsData';
import returnInitialOptions from '../../../../shared/helpers/returnInitialOptions/returnInitialOptions';
import { returnUpdatedAnalysisBody } from '../../../../shared/helpers/returnUpdatedAnalysisBody/returnUpdatedAnalysisBody';
import returnDigOptionsInitialValues from '../../../../shared/helpers/returnDigOptionsInitialValues/returnDigOptionsInitialValues';
import returnUpdatedAnalysisActionItems from '../../../../shared/helpers/returnUpdatedAnalysisActionItems/returnUpdatedAnalysisActionItems';
import { fetchGetJson as getAnalysisStructure } from '../../../../../../../../services/services';
import usePrevious from '../../../../../../../shared/customHooks/usePrevious';
import { returnMultiSelectionData } from '../../../../shared/helpers/returnMultiSelectionData/returnMultiSelectionData';
import { returnAnalysisActionsItems } from '../../../../shared/helpers/returnAnalysisActionsItems/returnAnalysisActionsItems';
import { getUpdatedAnalysisOptions } from '../../../../shared/helpers/getUpdatedAnalysisOptions/getUpdatedAnalysisOptions';
import { LanguageSwitchButton } from '../../../../shared/helpers/languageSwitchButton/LanguageSwitchButton';
import { fetchPost as fetchCopyXML, fetchPostJson as updateToplineData } from '../../../../../../../../services/services';
import { returnBrowser } from '../../../../shared/helpers/returnBrowser/returnBrowser';
import { CopyXmlModal } from '../../CopyXmlModal/CopyXmlModal';
import { ExpandResultModal } from '../../ExpandResultModal/ExpandResultModal';
import { Icon } from '../../../../../../../shared/Icon/Icon';
import { DropdownButton } from '../../../../../../../shared/DropdownButton/DropdownButton';
import { copyAnalysisQuestions } from '../../../../shared/helpers/copyAnalysisQuestions/copyAnalysisQuestions';
import { pasteAnalysisQuestions } from '../../../../shared/helpers/pasteAnalysisQuestions/pasteAnalysisQuestions';

export const DigTabContent = ({ onAutoSaveHandler, selectedTab, datasetId, datasetName, datasetType, optionsData, expandedOptions, showOptionsModal, handleOptionsDialogClose, openOptionsDialog, user, token, rangeItems, analysisFunctionalities, onApplyWeightSet }) => {
  const history = useHistory()
  const dispatch = useDispatch();
  const { theData, languages, defaultLanguage, editingLanguage, showFullscreenResult, structure, copyData } = useSelector(theState => (theState.setInitialDataReducer))
  const { state, undoQuestionOptions, redoQuestionOptions } = useSelector((theState) => (theState.digStateReducer));

  const params = useParams()
  const projectId = params.name
  const userSettings = useSelector(theState => theState.userSettingsReducer);
  const zoomLevel = userSettings.zoomLevels.digZoom;
  const [combineData, setCombineData] = useState([])
  const [showTarget, setShowTarget] = useState(false)
  const [targetData, setTargetData] = useState(structure);
  const [didMount, setDidMount] = useState(true)
  const [showError, setShowError] = useState(false)
  const [showWeightWizard, setShowWeightWizard] = useState(false)
  const [showQuestionEditorModal, setShowQuestionEditorModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState({ show: false, type: null, extension: null })
  const [checkedRows, setCheckedRows] = useState([])
  const [showCopyXmlModal, setShowCopyXmlModal] = useState({ show: false, xmlCode: '' })

  const allColumnsDisabled = state.firstColumn.filter(e => !e.disabled).length === 0
  const [didMountCheckedNums, setDidMountCheckedNums] = useState(true)
  const prevUpdateTable = state ? usePrevious(state.updateTable) : null

  const updatedItems = returnUpdatedAnalysisActionItems(analysisFunctionalities, items, datasetType) //export dropdown
  const actionItems = returnAnalysisActionsItems(undoQuestionOptions, redoQuestionOptions, true, datasetType, !state.firstColumn.length, state.checkedNum, copyData.elements) // actions dropdown

  useEffect(() => {
    dispatch({ type: "DIG_SET_UPDATE_TABLE_STATE", payload: { status: true } })
  }, [dispatch])

  useEffect(() => {
    const checkedRowsFiltered = state.firstColumn.filter((item) => item.selected).map(el => el.id)
    if (didMountCheckedNums) {
      const checkedRowsNums = checkedRowsFiltered.map(e => ({ source: 'firstColumn', id: e }))
      dispatch({ type: 'DIG_UPDATE_CHECKED_NUM', payload: checkedRowsNums })
      setDidMountCheckedNums(false)
    }
    setCheckedRows(checkedRowsFiltered)
  }, [state.firstColumn, didMountCheckedNums, dispatch])

  useEffect(() => {
    if (state.updateTable && state.updateTable !== prevUpdateTable) {
      if (state.newQuestionOptions?.TargetCode) {
        setShowError(false)
        let dataUrl = `an/projects/${history.location.pathname.split('/')[2]}/analysis/${datasetId}`
        if (datasetType === 'surveys') {
          dataUrl = `an/projects/${history.location.pathname.split('/')[2]}/analysis/surveys/${datasetId}`
        }
        const body = returnUpdatedAnalysisBody(params.analysisType, 'dig', state.newQuestionOptions, state.firstColumn, '', '', editingLanguage, defaultLanguage)
        updateToplineData(dataUrl, token, body)
          .then(res => {
            if (res?.error) {
              setShowError(true)
              dispatch({ type: 'DIG_SET_TABLE_STATE', payload: { value: null } })
            } else {
              setShowError(false)
              dispatch({ type: 'DIG_SET_TABLE_STATE', payload: { value: res.result } })
            }
          })
      } else {
        dispatch({ type: 'DIG_SET_TABLE_STATE', payload: { value: null } })
        if (state.firstColumn.length > 0) {
          setShowError(true)
        } else {
          setShowError(false)
        }
      }
      onAutoSaveHandler(state)
    }
  }, [state, dispatch, datasetId, token, onAutoSaveHandler, prevUpdateTable, history.location.pathname, datasetType, editingLanguage, params.analysisType, defaultLanguage])

  const onSelectQuestion = (e, id) => {
    dispatch({ type: 'DIG_SELECT_QUESTION', payload: { id: id, theData: theData } })
  }

  const onCheckQuestion = (e, id, source, itemId, shouldDefineRange) => {
    const updatedRowsData = returnMultiSelectionData(state.firstColumn, checkedRows, shouldDefineRange, itemId, (data) => setCheckedRows(data), 'selected')
    dispatch({ type: 'DIG_UPDATE_ROWS', payload: updatedRowsData })
    setDidMountCheckedNums(true)
  }

  const clearQuestions = (props) => {
    if (props.item.action === "DIG_CLEAR_QUESTION_OPTIONS") {
      const digValues = returnDigOptionsInitialValues(optionsData.data, user, optionsData.groups)
      const initialOptions = returnInitialOptions(defaultOptions[4].dig, user, optionsData.groups, optionsData.data)
      dispatch({ type: props.item.action, payload: { values: digValues, options: initialOptions } })

    } else if (props.item.action === "DIG_CLEAR_QUESTIONS") {
      setShowError(false)
      const digValues = returnDigOptionsInitialValues(optionsData.data, user, optionsData.groups)
      const initialOptions = returnInitialOptions(defaultOptions[4].dig, user, optionsData.groups, optionsData.data)
      dispatch({ type: props.item.action, payload: { values: digValues, options: initialOptions } })
    } else if (state.firstColumn.length > 0) {
      dispatch({ type: props.item.action })
    }
  }

  const handleOptionsUpdate = (newValues, values) => {
    const updatedQuestionOptions = Object.assign({}, state.newQuestionOptions, newValues)
    dispatch({ type: 'DIG_SET_QUESTION_OPTIONS', payload: { newValues: updatedQuestionOptions, values: values } })

    const updatedOptionsWithInitialValue = getUpdatedAnalysisOptions(state, values)
    dispatch({ type: 'UPDATE_DIG_UNDO_QUESTION_OPTIONS', payload: [...undoQuestionOptions, updatedOptionsWithInitialValue] })

    handleOptionsDialogClose()
  }

  const setCombineFilterData = (id) => {
    if (id === "UniverseCode") {
      const universeDataCopy = [...state.universeData]
      setCombineData(universeDataCopy)
    } else {
      const targetGroupCopy = Array.isArray(state.targetGroup) ? [...state.targetGroup] : []
      setCombineData(targetGroupCopy)
    }
  }

  const onOptionsClick = () => {
    const filterData = [...state.filterData];
    const universeData = [...state.universeData];
    const questionOptions = { ...state.questionOptions };
    const newQuestionOptions = { ...state.newQuestionOptions };

    dispatch({
      type: "SET_DIG_INITIAL_OPTIONS_DATA",
      payload: {
        initialFilterData: filterData,
        initialUniverseData: universeData,
        initialQuestionOptions: questionOptions,
        initialNewQuestionOptions: newQuestionOptions,
      }
    });

    openOptionsDialog();
  }

  const handleTargetOpen = (id) => {
    setCombineFilterData(id)
    setShowTarget(true)
  }

  const handleTargetClose = () => {
    setShowTarget(false)
  }

  const onSaveTargetOptions = (data, expression) => {
    dispatch({ type: 'DIG_SET_TARGET_GROUP', payload: { data: data } }) // Updates the Combine data from Filter Builder if "Visual Mode" is used

    const updatedQuestionOptions = Object.assign({}, state.newQuestionOptions, { TargetCode: expression })
    dispatch({ type: 'DIG_SET_QUESTION_OPTIONS', payload: { newValues: updatedQuestionOptions, values: state.questionOptions } }) // Updates the Expression data from Filter Builder

    setShowTarget(false)
  }

  const handleEditQuestion = (questions) => {
    dispatch({ type: 'DIG_EDIT_QUESTION', payload: { displayTable: state.displayTable, firstColumn: questions.firstColumn, scndColumn: questions.scndColumn } })
    setShowQuestionEditorModal(false);
  }

  const exportFunc = (props) => {
    if (state.firstColumn && state.firstColumn.length > 0 && allColumnsDisabled === false) {
      setShowExportModal({ show: true, action: props.item.action, type: props.item.actionName, extension: props.item.extension })
    }
  }

  const actionItemRender = (item) => {
    return (
      <ExportActionItem
        user={user}
        props={{ item }}
        state={state}
        datasetId={datasetId}
        history={history}
        token={token}
        disabled={allColumnsDisabled}
        showExportModal={(e) => exportFunc(e)}
        tab='dig' />
    )
  }

  const onAddToReport = () => {
    let dataUrl = `an/projects/${history.location.pathname.split('/')[2]}/analysis/${datasetId}`
    if (datasetType === 'surveys') {
      dataUrl = `an/projects/${history.location.pathname.split('/')[2]}/analysis/surveys/${datasetId}`
    }
    const reportQuestionsData = state.firstColumn.filter((item) => item.selected)
    const reportData = reportQuestionsData.map(item => {
      return {
        body: returnUpdatedAnalysisBody(params.analysisType, 'dig', state.newQuestionOptions, state.firstColumn, item.id),
        dataUrl: dataUrl,
        icon: "fas far fa-dot-circle fa-sm"
      }
    })
    dispatch({ type: 'SET_REPORT_COLUMN_DATA', payload: { data: reportData } });
    dispatch({ type: 'SET_REPORT_QUESTIONS_DATA', payload: { data: reportQuestionsData.map(item => { return { ...item, id: uuid(), type: "dig", active: false, selected: false } }) } })
    dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The dig result has been added to report successfully.' } });
  }

  const onActionHandler = (action) => {
    const updatedDigState = JSON.parse(JSON.stringify(state))
    const updatedDigQuestions = JSON.parse(JSON.stringify(updatedDigState.firstColumn))

    if (action === 'enable') {
      updatedDigQuestions.filter((item) => item.selected).forEach((el) => {
        el.disabled = false
      })
      updatedDigQuestions.forEach((item) => {
        item.selected = false
      })

      dispatch({ type: 'DIG_ENABLE_SELECTED_QUESTIONS', payload: updatedDigQuestions })
    } else if (action === 'disable') {
      updatedDigQuestions.filter((item) => item.selected).forEach((el) => {
        el.disabled = true
      })
      updatedDigQuestions.forEach((item) => {
        item.selected = false
      })
      dispatch({ type: 'DIG_DISABLE_SELECTED_QUESTIONS', payload: updatedDigQuestions })
    } else if (action === 'weight') {
      setShowWeightWizard(true)
    } else if (action === 'select rows') {
      const rows = state.firstColumn
      rows.map(e => e.selected = true)
      dispatch({ type: 'DIG_UPDATE_ROWS', payload: rows })
    } else if (action === 'deselect rows') {
      const rows = state.firstColumn
      rows.map(e => e.selected = false)
      dispatch({ type: 'DIG_UPDATE_ROWS', payload: rows })
    } else if (action === 'undo') {
      const updatedUndoQuestionOptions = [...undoQuestionOptions]
      const itemToAdd = undoQuestionOptions[undoQuestionOptions.length - 1]
      updatedUndoQuestionOptions.pop()
      dispatch({
        type: 'DIG_UNDO_LAST_CHANGE', payload: {
          questionOptions: { ...state.questionOptions, ...itemToAdd },
          newQuestionOptions: { ...state.newQuestionOptions, ...itemToAdd },
          undoQuestionOptions: updatedUndoQuestionOptions,
          redoQuestionOptions: [...redoQuestionOptions, state.questionOptions]
        }
      })
    } else if (action === 'redo') {
      const updatedRedoQuestionOptions = [...redoQuestionOptions]
      const itemToAdd = redoQuestionOptions[redoQuestionOptions.length - 1]
      updatedRedoQuestionOptions.pop()
      dispatch({
        type: 'DIG_REDO_LAST_CHANGE', payload: {
          questionOptions: { ...state.questionOptions, ...itemToAdd },
          newQuestionOptions: { ...state.newQuestionOptions, ...itemToAdd },
          redoQuestionOptions: updatedRedoQuestionOptions,
          undoQuestionOptions: [...undoQuestionOptions, state.questionOptions],
        }
      })
    } else if (action === 'copy xml') {
      let dataUrl = `an/projects/${history.location.pathname.split('/')[2]}/analysis/${datasetId}/adoc`
      if (datasetType === 'surveys') {
        dataUrl = `an/projects/${history.location.pathname.split('/')[2]}/analysis/surveys/${datasetId}/adoc`
      }
      fetchCopyXML(dataUrl, token, returnUpdatedAnalysisBody(params.analysisType, 'dig', state.newQuestionOptions, state.firstColumn))
        .then(res => {
          if (res && (res.message || res.error)) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
          } else {
            res.text().then(data => {
              returnBrowser() === 'Safari' ?
                setShowCopyXmlModal({ show: true, xmlCode: data })
                :
                navigator.clipboard.writeText(data).then(() => {
                  dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'XML definition has been copied to clipboard' } })
                });
            })
          }
        })
    } else if (action === 'copy-row-column') {
      copyAnalysisQuestions(state, dispatch, 'dig');
    } else if (action === 'paste-row-column') {
      pasteAnalysisQuestions(state, copyData, dispatch, 'dig');
    }
    setDidMountCheckedNums(true)
  }

  const setLanguageError = (err) => {
    setShowError(err)
  }

  return (
    <React.Fragment>
      {
        showQuestionEditorModal &&
        <QuestionEditor
          questions={state}
          source={state.selectedQuestionSource}
          handleClose={() => setShowQuestionEditorModal(false)}
          handleEditQuestion={handleEditQuestion} />
      }
      {showOptionsModal &&
        <OptionsDialog
          tab='dig'
          activeGroup={optionsData.selectedGroup}
          data={optionsData.data}
          dropdownValues={optionsData.dropdownValues}
          values={state.questionOptions}
          updateValues={state.newQuestionOptions}
          expandedOptions={expandedOptions}
          optionGroups={optionsData.groups}
          handleClose={handleOptionsDialogClose}
          handleUpdate={handleOptionsUpdate}
          setCombineFilterData={setCombineFilterData}
          datasetId={datasetId}
          combineFilterData={combineData}
          user={user}
          token={token}
          analysisType={datasetType}
          optionId={state.optionId}
          initialOptionsData={state.initialOptionsData}
          initialAndUpdateOptionsValues={state.initialAndUpdateOptionsValues}
          onApplyWeightSet={(value) => onApplyWeightSet(value, state)}
        />
      }
      {showTarget &&
        <OptionsFilterBuilder
          handleClose={handleTargetClose}
          filterData={targetData}
          datasetId={datasetId}
          manualFilterExpression={state.newQuestionOptions?.TargetCode}
          onSaveFilterOptions={onSaveTargetOptions}
          combineFilterData={combineData}
          analysisType={datasetType}
          option="TargetCode"
          token={token}
        />
      }
      {
        showExportModal?.show &&
        <ExportModal
          onHide={() => { setShowExportModal(false); dispatch({ type: 'CLOSE_EXPORT_MODAL' }) }}
          showExportModal={showExportModal}
          options={{ exportOptions: optionsData.exportOptions, values: optionsData.dropdownValues }}
          onExportHandler={returnActionsData}
          state={state}
          updatedLanguage={editingLanguage}
          defaultLanguage={defaultLanguage}
          projectType={params.analysisType}
          datasetId={datasetId}
          history={history}
          tab={'dig'}
          datasetName={datasetName}
          datasetType={datasetType}
          token={token} />
      }
      {showWeightWizard &&
        <WeightWizard
          handleClose={() => setShowWeightWizard(false)}
          token={token}
          datasetId={datasetId}
          user={user}
        />
      }
      {
        (user.plan === 'basic' || user.plan === 'essential_yearly' || user.plan === 'essential_monthly') && !user.isSubAccount ?
          <UpgradePlanMessage className="tab-overlay" user={user} />
          : null
      }
      {showCopyXmlModal.show && <CopyXmlModal xmlCode={showCopyXmlModal.xmlCode} onHide={() => setShowCopyXmlModal({ show: false, xmlCode: '' })} />}
      <div className={`d-flex flex-column overflow-hidden flex-fill ${(user.plan === 'basic' || user.plan === 'essential_yearly' || user.plan === 'essential_monthly') && !user.isSubAccount ? 'blur' : null}`}>
        <div className={"justify-content-between flex-wrap d-flex border-left pr-1"}>
          <div className="btn-group m-2 analysis-actions">
            <Button
              disabled={state.checkedNum?.length && state.checkedNum.length > 0 && editingLanguage === defaultLanguage ? false : true}
              className={"btn btn-outline-analyze px-2"}
              onClick={() => setShowQuestionEditorModal(true)}>Edit
            </Button>
            <Button
              className={"btn btn-outline-analyze px-2"}
              disabled={checkedRows.length > 0 ? false : true}
              onClick={onAddToReport}>Add to report
            </Button>
            <Button
              className={"btn btn-outline-analyze px-2"}
              onClick={() => handleTargetOpen("TargetCode")}>Set target group
            </Button>
            <div className="btn-group">
              <DropDownButton
                text={"Clear"}
                textField="actionName"
                icon="fas fa fa-caret-down"
                className='analyze-actions-button'
                buttonClass={"btn btn-outline-analyze rounded-0 d-flex flex-row-reverse px-2"}
                items={clearQuestionItems}
                onItemClick={(props) => clearQuestions(props)}
                itemRender={(props) => (
                  <div className="p-1 dropdown-button__clear--small">
                    <span>
                      {props.item.actionName}
                    </span>
                  </div>
                )}
              />
            </div>
            <div className="btn-group">
              <DropDownButton
                text={"Actions"}
                textField="actionName"
                icon="fas fa fa-caret-down"
                className='analyze-actions-button'
                buttonClass={"btn btn-outline-analyze rounded-0 d-flex flex-row-reverse px-2"}
                items={actionItems.filter(e => !e.actionName.includes("Columns"))}
                onItemClick={(e) => onActionHandler(e.item.action)}
                popupSettings={{ popupClass: 'actions-dropdown' }}
              />
            </div>
            <Button
              className="btn btn-outline-analyze px-2"
              onClick={onOptionsClick}>Options
            </Button>
            {languages.length > 1 ?
              <div className='btn-group' role='group'>
                <LanguageSwitchButton
                  languages={languages}
                  editingLanguage={editingLanguage}
                  defaultLanguage={defaultLanguage}
                  tabType={"dig"}
                  newQuestionOptions={state.newQuestionOptions}
                  firstColumn={state.firstColumn}
                  setLanguageError={setLanguageError}
                  token={token}
                  requestUrl={datasetType === "surveys" ? `an/projects/${projectId}/analysis/surveys/${datasetId}` : `an/projects/${projectId}/analysis/${datasetId}`}
                  dispatchType={'DIG_SET_TABLE_STATE'}
                  dataType={updateToplineData}
                  analysisBodyType={"dig"}
                  componentType={"dig"}
                />
              </div> : null}
          </div>

          <div className="btn-group m-2 analysis-actions">
            <ZoomButtons
              userSettings={userSettings}
              zoomType={'digZoom'}
            />
            <DropdownButton
              hideChevron={true}
              className='btn-outline-analyze strong px-2'
              items={updatedItems}
              renderItem={actionItemRender}
              renderMainButton={() => (
                <span style={{ fontFamily: "Walr Inter", fontSize: "13px", fontWeight: 600 }} className='user-info'>Export</span>
              )}
            />
            <Tooltip openDelay={100} position='auto' anchorElement={'target'}>
              <button
                type='button'
                className="btn btn-outline-analyze btn-ic p-1"
                disabled={state?.firstColumn?.length === 0}
                title="Fullscreen"
                onClick={() => dispatch({ type: 'SET_SHOW_FULLSCREEN_RESULT', payload: true })}>
                <Icon type="expand" />
              </button>
            </Tooltip>
          </div>
        </div>
        <div className="w-100 d-flex overflow-hidden flex-fill border-table-area">
          <div className="w-100 d-flex digData">
            <div className='d-flex flex-column'>
              <DroppableColumn onCheck={onCheckQuestion} firstColumn={true} state={state} onSelectQuestion={onSelectQuestion} tab="dig" user={user} rangeItems={rangeItems} />
            </div>
            <div className="w-100 h-100 d-flex flex-column overflow-hidden bg-white" style={{ fontSize: `${((zoomLevel) * 0.875).toString()}rem` }}>
              {
                showFullscreenResult &&
                <ExpandResultModal
                  result={state.displayTable}
                  onHide={() => dispatch({ type: 'SET_SHOW_FULLSCREEN_RESULT', payload: false })}
                />
              }
              {
                !showError ?
                  state.updateTable && state.firstColumn.length !== 0 ?
                    <InProgressOverlay theme="primary" type="fullscreen" />
                    :
                    <JsxParser jsx={state.displayTable} />
                  :
                  <div className="d-flex m-auto p-3">
                    <div className="alert alert-danger" role="alert">
                      <span>Target expression is missing in dig element. Please set target group.</span>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div >
      </div >
    </React.Fragment >
  )
}